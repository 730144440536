////
/// ============================================================================
/// COMPONENT / Table Of Content
/// ============================================================================
///
/// @group components
////

.c-toc {
    &__item {
        @extend %type-regular;
        color: currentColor;
        border-bottom: 1px solid color(gray, 300);
        display: flex;
        height: 100%;
        padding: 0.5rem 0;
        position: relative;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
}
