////
/// ============================================================================
/// ABOUT
/// ============================================================================
///
/// @group templates
////

.c-about {
    @include media(">=m") {
        display: flex;
    }

    &__text {
        padding: 4rem 0;
        @include media(">=m") {
            align-items: center;
            display: flex;
            margin-right: gutter();
            padding: 6rem 0;
            width: span(7);
        }

        h1 {
            span {
                color: color(primary, base);
            }
        }

        p {
            opacity: 0.7;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .c-about__wrap {
            @include media(">=m") {
                width: span(5 of 7);
            }
            @include media(">=xl") {
                margin-left: (span(1 of 7) + gutter(1 of 7));
                width: span(4 of 7);
            }
        }
    }

    &__media {
        border-bottom: 1px solid color(gray, 300);
        text-align: center;
        @include media(">=m") {
            background: color(primary, base);
            border-bottom: 0;
            padding-top: 2rem;
            position: relative;
            text-align: left;
            width: span(5);

            &::after {
                @include absolute(top 0, -1);
                background: color(primary, base);
                content: '';
                display: block;
                height: 100%;
                right: -$container-side;
                width: 100%;
                @include media(">=#{$container}") {
                    right: calc(-1 * (50% - (((100vw - 100%) - #{$container}) / -2)));
                    width: calc(50% - (((100vw - 100%) - #{$container}) / -2));
                }
            }

            img {
                margin-left: -(span(2 of 5));
            }
        }
    }
}
