////
/// ============================================================================
/// COMPONENT / Read More
/// ============================================================================
///
/// @group components
////

.c-read-more {
    position: relative;

    &__btn {
        @include absolute(bottom 0, 1);
        color: color(text, base);

        &.-hide,
        &.-show:target {
            display: none;
        }

        &.-show:target {
            & ~ .-hide {
                display: block;
            }
            & ~ .c-read-more__panel {
                max-height: 2000px;
            }
            & ~ .c-read-more__fade {
                height: 3rem;
                margin-top: 0;
            }
        }
    }

    &__panel {
        position: relative;
        max-height: 10rem;
        overflow: hidden;
        transition: max-height .5s ease;

        *:first-of-type {
            margin-top: 0;
        }
        *:last-of-type {
            margin-bottom: 0;
        }
    }

    &__fade {
        background: linear-gradient(to bottom, rgba(#fff, 0) 0%,rgba(#fff, 1) 70%);
        height: 10rem;
        margin-top: -10rem;
        position: relative;
    }
}