////
/// ============================================================================
/// BASE / Fonts
/// ============================================================================
///
/// @link https://www.zachleat.com/web-fonts/demos/critical-foft-preload-polyfill.html
///
/// @group base
////


@font-face {
    font-family: EuropaNuova;
    src: url("fonts/EuropaNuovaRegular-subset.woff2") format("woff2"),
    url("fonts/EuropaNuovaRegular-subset.woff") format("woff");
    font-weight: 400;
}

@font-face {
    font-family: EuropaNuovaBold;
    src: url("fonts/EuropaNuovaBold-subset.woff2") format("woff2"),
    url("fonts/EuropaNuovaBold-subset.woff") format("woff");
    font-weight: 600;
}

@font-face {
    font-family: EuropaNuovaExtraBold;
    src: url("fonts/EuropaNuovaExtraBold-subset.woff2") format("woff2"),
    url("fonts/EuropaNuovaExtraBold-subset.woff") format("woff");
    font-weight: 900;
}

@font-face {
    font-family: PTSerif;
    src: url("fonts/PTSerif-Regular-subset.woff2") format("woff2"),
    url("fonts/PTSerif-Regular-subset.woff") format("woff");
    font-weight: 400;
}

@font-face {
    font-family: PTSerifBold;
    src: url("fonts/PTSerif-Bold-subset.woff2") format("woff2"),
    url("fonts/PTSerif-Bold-subset.woff") format("woff");
    font-weight: 600;
}


%type-regular {
    .fonts-stage-2 & {
        font-family: EuropaNuova, sans-serif;
        font-weight: $font-weight-regular;
    }
}

body {
    @extend %type-regular;
}

%type-bold {
    .fonts-stage-2 & {
        font-family: EuropaNuovaBold, sans-serif;
        font-weight: $font-weight-semibold;
    }
}

strong,
#{headings(3, 6)},
.#{headings(3, 6)} {
    @extend %type-bold;
}

%type-black {
    .fonts-stage-2 & {
        font-family: EuropaNuovaExtraBold, sans-serif;
        font-weight: $font-weight-black;
    }
}

#{headings(1, 2)},
.#{headings(1, 2)} {
    @extend %type-black;
    letter-spacing: -0.01em;
}

/**
 * Content Type
 */
%type-content {
    .fonts-stage-2 & {
        font-family: PTSerif, serif;
        font-weight: $font-weight-regular;
    }
}

%type-content-bold {
    .fonts-stage-2 & {
        font-family: PTSerifBold, serif;
        font-weight: $font-weight-bold;
    }
}

.o-content {
    @extend %type-content;

    strong {
        @extend %type-content-bold;
    }
}
