////
/// ============================================================================
/// COMPONENT / Back To Top
/// ============================================================================
///
/// @group components
////

.c-backtotop {
    @include fixed(bottom $container-side right $container-side, 5);
    background: $link-color;
    border-radius: 50em;
    color: #fff;
    display: flex;
    padding: 0.5em;
    text-decoration: none;
    transition: 0.3s;

    @include media(">=l") {
        @include absolute(bottom 3rem right 2em); // 3rem u-trailing-m
        align-items: flex-end;
        background: none;
        border-radius: 0;
        color: color(text, base);
        height: 140px;
        overflow: hidden;
        padding: 0;
        transform: rotate(-90deg);
        transform-origin: center center;
        transition: none;
        width: 140px;
    }

    &:hover {
        background: $link-hover-color;
        color: color(text, light);
        text-decoration: none;
        @include media(">=l") {
            background: none;
        }
    }

    .u-type {
        display: none;
        line-height: 20px; // svg size
        text-transform: uppercase;
        @include media(">=l") {
            display: inline-block;
        }
    }

    svg {
        display: inline-block;
        @include media(">=l") {
            transform: rotate(90deg);
        }
    }

    ::selection {
        background: transparent;
        color: color(text, base);
        text-shadow: none;
    }
}

.js {
    .c-backtotop {
        opacity: 0;
        visibility: hidden;

        &.is-visible {
            opacity: 1;
            visibility: visible;
        }
    }
}