////
/// ============================================================================
/// LEARNDASH / Course
/// ============================================================================
///
/// @group learndash
////

.learndash-wrapper {
	.ld-course-status.ld-course-status-not-enrolled {
		display: none;
	}

	.ld-item-list {
		.ld-item-list-item {
			border: 1px solid color(gray, 300);

			&:hover {
				border-color: color(primary, base);
			}
		}

		&.ld-course-list,
		&.ld-lesson-list {
			.ld-lesson-section-heading {
				font-size: 1.5rem;
			}

			h2 {
				display: none;
			}
		}
	}

	.learndash_mark_complete_button:hover {
		background-color: #047c64;
	}
}


.learndash-wrapper .ld-item-list .ld-item-list-item .ld-item-list-item-preview {

	@include media(">=640px") {
		display: grid;
		grid-column-gap: 2em;
		grid-row-gap: 1rem;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, auto);
	}

	.ld-item-name {
		align-self: self-end;
		grid-column: 1 / span 3;
		grid-row: 1;
	}

	.ld-item-figure {
		grid-column: span 4;
		grid-row: 1 / span 2;
		margin-bottom: 1rem;
		margin-top: 1rem;
		max-width: none;
		width: 100%;

		@include media(">=640px") {
			margin: 0;
		}

		a {
			display: block;
		}
	}

	.ld-item-excerpt {
		align-self: self-start;
		font-size: 0.9rem;
		grid-column: 1 / span 3;
		grid-row: 2;
		opacity: 0.8;

		@include media(">=640px") {
			padding-left: calc(26px + .5em);
		}
	}

	.ld-item-details {
		display: none
	}
}

.sfwd-courses {
	.o-page__header {
		padding-bottom: 2rem;
		padding-top: 4rem;
	}

	.o-page__body {
		@include media(">=m") {
			width: 100%;
		}
	}

	.learndash-wrapper .ld-tabs {
		margin: 2rem 0;
	}
}
