////
/// ============================================================================
/// WOOCOMMERCE / Account
/// ============================================================================
///
/// @group woocommerce
////

// Layout
.woocommerce-account {
	.woocommerce {
		display: grid;
		gap: var(--grid-gap);
		grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));

		&::before,
		&::after {
			display: none; // Reset WooCommerce Style
		}

		.clear {
			display: none;
		}
	}

	[class^="woocommerce-"],
	[class^="woocommerce-"] + p,
	[class*="form-row"] {
		float: none; // Reset WooCommerce Style
		grid-column: span var(--grid-columns);
		width: auto; // Reset WooCommerce Style
	}

	.woocommerce-MyAccount-navigation {
		order: 1;

		@include media(">=l") {
			grid-column: span 4;
			order: 0;
			padding-right: var(--grid-gap);
		}
	}

	.woocommerce-MyAccount-content {
		@include media(">=l") {
			grid-column: 5 / span 8;
		}

		h2 {
			margin-top: 2rem;
		}

		> h2:first-child,
		> p:first-child,
		> .woocommerce-notices-wrapper:empty + h2,
		> .woocommerce-notices-wrapper:empty + p {
			margin-top: 0;
		}
	}

	.woocommerce-ResetPassword {
		display: grid;
		gap: calc(var(--grid-gap) / 2);
		@include media(">=l") {
			grid-column: 4 / span 6;
		}

		p {
			float: none; // Reset WooCommerce Style
			grid-column: span var(--grid-columns);
			width: auto; // Reset WooCommerce Style
		}
	}
}

// Navigation
.woocommerce-MyAccount-navigation {
	ul {
		@include fluid-type(font-size, 18px, 22px);
		list-style: none;
		margin-bottom: 0;
		margin-top: 0;
		padding-left: 0;

		li {
			&:not(:last-child) {
				border-bottom: 1px solid color(gray, 300);
			}

			a {
				@include fluid-type(padding-top padding-bottom, 14px, 16px);
				padding-left: 0;
				padding-right: 0;
				align-items: center;
				color: color(text, base);
				cursor: pointer;
				display: flex;
				justify-content: space-between;

				svg {
					@include fluid-type(font-size, 18px, 22px);
					margin-left: 0.5em;
					opacity: 0.4;
				}

				&:hover {
					color: color(primary, base);

					svg {
						opacity: 1;
					}
				}
			}

			&.is-active a {
				color: color(primary, base);

				svg {
					opacity: 1;
				}
			}
		}
	}
}

// Content
.woocommerce-MyAccount-content {
	h2, h3, h4,
	legend {
		@extend %h4;
	}

	.col2-set,
	.edit-account,
	[class*="field-wrapper"] {
		display: grid;
		gap: calc(var(--grid-gap) / 2);
		grid-template-columns: repeat(var(--col2-set-grid), minmax(0, 1fr));

		--col2-set-columns: 12;
		--col2-set-grid: 12;

		@include media(">=m") {
			--col2-set-columns: 6;
		}
		@include media(">=l") {
			--col2-set-columns: 4;
			--col2-set-grid: 8;
		}

		&::before,
		&::after {
			display: none; // Reset WooCommerce Style
		}

		[class*="col-"],
		[class*="form-row"] {
			float: none; // Reset WooCommerce Style
			grid-column: span var(--col2-set-columns);
			width: auto; // Reset WooCommerce Style
		}

		legend,
		.form-row-wide {
			grid-column: span var(--col2-set-grid);
		}
	}

	.edit-account {
		@include media(">=m") {
			gap: calc(var(--grid-gap) / 2);
		}

		fieldset {
			display: contents;

			legend {
				margin-top: 2rem;
			}

			p:not(:first-of-type) {
				grid-column: span var(--col2-set-columns);
			}

			+ div + p {
				grid-column: span var(--col2-set-grid);
			}
		}
	}

	.form-row input + span {
		@include fluid-type(font-size, 18px, 22px);
		display: block;
		margin-top: 0.2rem;
		opacity: 0.5;
	}
}

.woocommerce-address-fields__field-wrapper {
	margin-bottom: 1rem;
}

// Login
#customer_login {
	display: contents;

	&::before,
	&::after {
		display: none; // Reset WooCommerce Style
	}

	.woocommerce-form {
		display: grid;
		gap: calc(var(--grid-gap) / 2);
		grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
		margin: 0; // Reset WooCommerce Style
	}

	[class*="col-"] {
		float: none; // Reset WooCommerce Style
		grid-column: span var(--grid-columns);
		width: auto; // Reset WooCommerce Style
		@include media(">=m") {
			--grid-columns: 6;
		}
	}
}

// Pannello ordini
.woocommerce-orders-table__cell-order-actions {
	white-space: nowrap;
}

.woocommerce table.my_account_orders .button {
	@include fluid-type(font-size, 18px, 22px);
	line-height: 1;
	padding: 0.5rem;

	&:not(:last-child) {
		margin-right: 0.2em;
	}
}

// Pannello punti (https://gitlab.metaline.it/metaline/mymay/issues/31)
th.points-rewards-event-points {
	white-space: nowrap;
}

// Address
.woocommerce .woocommerce-order-details,
.woocommerce .woocommerce-customer-details {
	margin-top: 4rem;

	.woocommerce-column--billing-address {
		@include media(">=l") {
			border-right: 1px solid color(border, base);
		}
	}

	h2 {
		@include media(">=l") {
			margin-top: 0;
		}
	}

	address {
		border: none;
		padding: 0;
	}
}

// Tracking
.c-tracking {
	list-style: circle;
}
