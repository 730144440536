////
/// ============================================================================
/// COMPONENT / Submenu
/// ============================================================================
///
/// @group components
////

.c-tabmenu {
    display: inline-block;
    font-size: 0;
    margin: 2rem 0 0 0;
    padding: 0 gutter();
    li {
        border-bottom: 1px solid color(gray, 300);
        display: inline-block;
        font-size: 1rem;
        list-style: none;
        outline: none;
        padding-left: 60px;
        &:first-child {
            padding-left: 0;
        }
        a {
            color: color(text, light);
            display: block;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 4px;
            margin-top: 2px;
            padding: 9px 0 11px;
            position: relative;
            text-align: left;
            text-decoration: none;
            z-index: 0;
            &.is-active {
                color: $link-color;
                cursor: default;
                pointer-events: none;
                z-index: 10;
                &:after {
                    @include absolute(bottom -5px left 0);
                    border-bottom: 1px solid $link-color;
                    content: '';
                    width: 100%;
                }
            }
        }
    }
}


.c-catmenu {
    list-style: none;
    padding: 0.5rem 1rem;

    &__item {
        border-bottom: 1px solid color(gray, 300);
        &:last-of-type {
            border-bottom: 0;
        }

        a {
            color: color(text, light);
            display: block;
            padding: 0.5rem 0;
            text-decoration: none;
        }

        &.is-active a {
            color: $link-color;
        }
    }
}
