////
/// ============================================================================
/// COMPONENT / Category
/// ============================================================================
///
/// @group components
////

.c-category {
    list-style: none;
    margin: 0;
    padding: 0;

    &.-list {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;

        li {
            margin-bottom: gutter();
            width: span(6);
            &:not(:nth-of-type(2n)) {
                margin-right: gutter();
            }
            @include media(">=s") {
                width: span(4);
                &:not(:nth-of-type(2n)) {
                    margin-right: 0;
                }
                &:not(:nth-of-type(3n)) {
                    margin-right: gutter();
                }
            }
            @include media(">=m") {
                width: span(3);
                &:not(:nth-of-type(3n)) {
                    margin-right: 0;
                }
                &:not(:nth-of-type(4n)) {
                    margin-right: gutter();
                }
            }
            @include media(">=l") {
                width: span(2);
                &:not(:nth-of-type(4n)) {
                    margin-right: 0;
                }
                &:not(:nth-of-type(6n)) {
                    margin-right: gutter();
                }
            }


            a {
                background: #fff;
                box-shadow: inset 0 0 0 1px color(gray, 300);
                border-radius: $border-radius;
                color: color(text, base);
                display: block;
                padding: 1.5rem;
                text-align: center;
                text-decoration: none;
                height: 100%;

                &:hover {
                    box-shadow: inset 0 0 0 3px color(primary, base);
                    color: color(text, base);
                }

                svg {
                    font-size: 6em;
                }
                strong {
                    display: block;
                    margin-top: 1rem;
                }
            }
        }
    }
}