////
/// ============================================================================
/// COMPONENT / Entry
/// ============================================================================
///
/// @group components
////

.c-entry {
    @include fluid-type(margin-top margin-bottom, 1rem, 1.5rem);
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    width: 100%;

    .o-archive & {
        @include fluid-type(margin-top margin-bottom, 1rem, 2rem);
    }

    &__link {
        &:hover + .c-entry__media {
            filter: saturate(1);

            & + .c-entry__wrap h3,
            & + .c-entry__wrap .c-btn {
                color: $link-color;
            }
        }
    }

    &__media {
        align-items: center;
        display: flex;
        filter: saturate(0.8);
        overflow: hidden;
        position: relative;
        width: 100%;

        figure {
            border-radius: $border-radius;
            overflow: hidden;
            position: relative;
            width: 100%;
            z-index: 1;
        }

        img {
            @include transform(null, 0);
            border-radius: $border-radius;
        }
    }

    &__wrap {
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        justify-content: center;
        padding: 1rem 1em;
        width: 100%;
        @include media(">=s") {
            padding-bottom: 0;
            padding-right: 0;
            padding-top: 0;
        }
    }

    &__header {
        .c-list {
            margin-bottom: 0.5rem;
            position: relative;
            z-index: 5;
        }

        h3 {
            margin-bottom: 0;

            .c-content & {
                margin-top: 0; // Reset .c-content
            }
        }
    }

    &__body {
        margin-top: 1rem;
        p {
            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &.-cover {
        border-radius: $border-radius;
        flex-direction: column;

        .c-entry__link {
            z-index: 1;
            &:hover + .c-entry__media {
                &::before {
                    opacity: 0.8;
                }

                & + .c-entry__wrap h3 {
                    color: #fff;
                }
            }
        }

        .c-entry__wrap {
            @include fluid-type(padding, 1rem, 2rem);
            @include absolute(bottom 0 left 0);
        }

        .c-entry__media {
            &::before {
                @include absolute(top 0 left 0, 2);
                background: linear-gradient(
                        to bottom,
                        hsla(330, 0%, 11%, 0.45),
                        ease-in-out,
                        hsla(330, 0%, 11%, 0.65)
                ); // https://larsenwork.com/easing-gradients/
                content: '';
                display: block;
                height: 100%;
                width: 100%;
            }

            figure::before {
                padding-bottom: percentage(5 / 6); // Aspect Ratio 6:5
            }
        }

        .c-entry__header {
            color: #fff;
            text-align: center;
            h3 {
                @extend .u-type.-l;
                @extend %type-bold;
                color: currentColor;
            }
            svg {
                font-size: 4.5em;
            }
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @include media(">=#{$breakpoint}") {
            &.-portrait\@#{$breakpoint} {
                flex-direction: column;

                .c-entry__wrap {
                    padding: 1rem 1em 0 1em;
                    width: 100%;
                }

                .c-entry__media {
                    width: 100%;
                }
            }
        }
    }
}