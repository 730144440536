////
/// ============================================================================
/// OBJECT / Section
/// ============================================================================
///
/// @group objects
////

.o-section {
    position: relative;
    &__header {
        &.-reduced {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            @include media(">=m") {
                max-width: span(8);
            }
            @include media(">=xl") {
                max-width: span(6);
            }
        }

        h2, h3 {
            margin: 0;
        }
        p {
            opacity: 0.7;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &__content {}
}
