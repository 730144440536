////
/// ============================================================================
/// WOOCOMMERCE / Forms
/// ============================================================================
///
/// @group woocommerce
////

// Reset WooCommerce Style
.woocommerce form {
	p,
	.form-row {
		margin: 0;
		padding: 0;
	}

	.form-row {
		label {
			@extend %c-label;
		}

		input.input-text {
			@extend %c-input;
		}

		.form-row textarea {
			@extend %c-textarea;
		}
	}

	legend {
		border: 0;
	}
}

.wc-stripe-elements-field,
.wc-stripe-iban-element-field {
	@extend %c-input;
}
