.woocommerce {
	a.button.alt,
	button.button.alt,
	input.button.alt,
	#respond input#submit,
	a.button,
	button.button,
	input.button,
	#respond input#submit,
	a.button,
	button.button,
	input.button {
		@extend %c-btn;
	}

	.wc-proceed-to-checkout .checkout-button,
	button#place_order {
		background: color(primary, base) !important;
		width: 100%;

		&:hover {
			background: color(primary, light) !important;
		}
	}
}
