////
/// ============================================================================
/// GRAVITY FORMS / Fields
/// ============================================================================
///
/// @group vendors
////

body {
	.gform_wrapper.gravity-theme {
		.ginput_container_date input {
			width: 100%;
		}

		[type="color"],
        [type="date"],
		[type="datetime-local"],
		[type="datetime"],
		[type="email"],
		[type="month"],
		[type="number"],
		[type="password"],
		[type="search"],
		[type="tel"],
		[type="text"],
		[type="time"],
		[type="url"],
		[type="week"] {
			@extend %c-input;
		}

		select {
			@extend %c-select;
		}

		textarea {
			@extend %c-textarea;
		}

        [type="checkbox"] {
            @extend %c-checkbox;
        }

        [type="radio"] {
            @extend %c-radio;
        }

        [type="checkbox"],
        [type="radio"] {
            & + label {
                line-height: 1.3;
            }
        }

        .gfield_html_formatted {
            @extend %c-label;
            color: $input-text-color;
            font-weight: $font-weight-regular;
        }

		.gform_footer,
		.gform_page_footer {
			text-align: center;
			margin: 0;
			padding: 0;

			input,
            button {
				@extend %c-btn;

                + img {
                    margin-left: 1em;
                }
			}

			input[type="submit"] {
				margin-top: 16px;
			}
		}
	}

	.gform_wrapper .gfield_required {
		color: $input-text-color;
	}
}
