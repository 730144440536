////
/// ============================================================================
/// ELEMENTOR
/// ============================================================================
///
/// @group vendors
////

.elementor .nf-form-content button,
.elementor .nf-form-content input[type=button],
.elementor .nf-form-content input[type=submit] {
	background-color: #ed6524;
	border-color: #e55713;
	border-radius: 100px 100px 100px 100px;
	border-style: solid;
	color: #fff;
	font-size: 1.1em;
	font-weight: bold;
	height: 70px;
	padding-left: 1em;
	padding-right: 1em;
	text-decoration: none;
}

.elementor .nf-form-content button:hover,
.elementor .nf-form-content input[type=button]:hover,
.elementor .nf-form-content input[type=submit]:hover {
	background-color: #e55713;
	color: #fff;
}

.elementor-widget-reviews.elementor-pagination-type-bullets .swiper-container,
.elementor-widget-reviews.elementor-pagination-type-fraction .swiper-container,
.elementor-widget-testimonial-carousel.elementor-pagination-type-bullets .swiper-container,
.elementor-widget-testimonial-carousel.elementor-pagination-type-fraction .swiper-container {
	padding-bottom: 4rem;
}
