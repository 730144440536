////
/// ============================================================================
/// UTILITY / Spacing
/// ============================================================================
///
/// @group utilities
////

/**
 * Padding Leading
 */
.u-leading {
    &-xs {
        @include fluid-type(padding-top, 0.5rem, 1rem);
    }
    &-s {
        @include fluid-type(padding-top, 1.5rem, 2rem);
    }
    &-m {
        @include fluid-type(padding-top, 2rem, 3rem);
    }
    &-l {
        @include fluid-type(padding-top, 3rem, 4rem);
    }
    &-xl {
        @include fluid-type(padding-top, 4rem, 6rem);
    }
}


/**
 * Padding Trailing
 */
.u-trailing {
    &-xs {
        @include fluid-type(padding-bottom, 0.5rem, 1rem);
    }
    &-s {
        @include fluid-type(padding-bottom, 1.5rem, 2rem);
    }
    &-m {
        @include fluid-type(padding-bottom, 2rem, 3rem);
    }
    &-l {
        @include fluid-type(padding-bottom, 3rem, 4rem);
    }
    &-xl {
        @include fluid-type(padding-bottom, 4rem, 6rem);
    }
}