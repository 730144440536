////
/// ============================================================================
/// GUTENBERG
/// ============================================================================
///
/// @group vendors
////

.wp-block-columns {
    p:empty {
        @include media("<l") {
            display: none;
        }
    }
}

.wp-block-media-text {
    @supports not (display: grid) {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    @include media("<m") {
        display: flex;
        flex-direction: column;
    }

    .wp-block-media-text__media {
        align-items: center;
        display: flex;
        @supports not (display: grid) {
            flex: 1 1 50%;
        }
        @supports (display: grid) {
            height: 100%;
        }
        @include media(">=m") {
            min-height: 680px;
            padding-right: gutter(6) / 2;
            @supports not (display: grid) {
                padding-left: gutter(6) / 2;
            }
        }
        @include media(">=l") {
            padding-right: gutter(5) / 2;
            @supports not (display: grid) {
                padding-left: gutter(5) / 2;
            }
        }
        @include media(">=xl") {
            padding-right: gutter(4) / 2;
            @supports not (display: grid) {
                padding-left: gutter(4) / 2;
            }
        }
    }

    .wp-block-media-text__content {
        justify-self: flex-end;
        padding-left: $container-side;
        padding-right: $container-side;
        @include media(">=m") {
            max-width: calc(50vw - #{$container-side});
            padding-left: gutter(6) / 2;
            padding-right: 0;
            @supports not (display: grid) {
                max-width: none;
                padding-right: gutter(6) / 2;
            }
        }
        @include media(">=l") {
            padding-left: gutter(5) / 2;
            @supports not (display: grid) {
                padding-right: gutter(5) / 2;
            }
        }
        @include media(">=xl") {
            padding-left: gutter(4) / 2;
            @supports not (display: grid) {
                padding-right: gutter(4) / 2;
            }
        }
        @include media(">=#{$container}") {
            max-width: calc(50vw - ((100vw - #{$container}) / 2));
            @supports not (display: grid) {
                max-width: none;
            }
        }
        @supports not (display: grid) {
            flex: 1 1 50%;
        }
    }

    &.has-media-on-the-right {
        @supports not (display: grid) {
            flex-direction: row-reverse;
        }

        .wp-block-media-text__media {
            @include media(">=m") {
                padding-left: gutter(6) / 2;
                padding-right: 0;
                @supports not (display: grid) {
                    padding-right: gutter(6) / 2;
                }
            }
            @include media(">=l") {
                padding-left: gutter(5) / 2;
                padding-right: 0;
                @supports not (display: grid) {
                    padding-right: gutter(5) / 2;
                }
            }
            @include media(">=xl") {
                padding-left: gutter(4) / 2;
                padding-right: 0;
                @supports not (display: grid) {
                    padding-right: gutter(4) / 2;
                }
            }
        }

        .wp-block-media-text__content {
            justify-self: flex-start;
            @include media(">=m") {
                padding-left: 0;
                padding-right: gutter(6) / 2;
                @supports not (display: grid) {
                    padding-left: gutter(6) / 2;
                }
            }
            @include media(">=l") {
                padding-left: 0;
                padding-right: gutter(5) / 2;
                @supports not (display: grid) {
                    padding-left: gutter(5) / 2;
                }
            }
            @include media(">=xl") {
                padding-left: 0;
                padding-right: gutter(4) / 2;
                @supports not (display: grid) {
                    padding-left: gutter(4) / 2;
                }
            }
        }
    }
}

.wp-block-group {
    &.alignfull {
        padding-left: $container-side;
        padding-right: $container-side;
    }

    &__inner-container {
        margin-left: auto;
        margin-right: auto;
        max-width: $container;

        .wp-block-group .wp-block-group__inner-container {
            @include media(">=m") {
                margin-left: auto;
                margin-right: auto;
                width: span(8);
            }
            @include media(">=l") {
                width: span(6);
            }
        }
    }

    .wp-block-media-text:not(.alignwide),
    .wp-block-media-text:not(.alignfull) {
        @include media(">=l") {
            margin-left: auto;
            margin-right: auto;
            max-width: span(10);
        }
        @include media(">=xl") {
            max-width: span(8);
        }
    }
}


.has-primary-background-color {
    background-color: color(primary, base);
}

.has-gray-100-background-color {
    background-color: color(gray, 100);
}

.has-gray-200-background-color {
    background-color: color(gray, 200);
}


.wp-block-blockgallery-carousel {
    &::before,
    &::after {
        @include absolute(top 0 left -1px, 10);
        background: linear-gradient(to right, rgba(#fff, 1) 0%, rgba(#fff, 0) 100%);
        content: '';
        display: block;
        height: 100%;
        width: 20%;
    }

    &::after {
        background: linear-gradient(to right, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
        left: auto;
        right: -1px;
    }

}

.wp-block-columns {
    @include media(">=782px") {
        flex-wrap: wrap;
    }
}

.wp-block-column {
    width: 100%;
    @include media(">=600px") {
        flex-basis: auto;
        &:nth-child(2n) {
            margin-left: 0;
        }
    }
    @include media(">=782px") {
        &:not(:first-child) {
            margin-left: 0;
        }
    }
    @include media(">=m") {
        width: span(4);

        &:nth-child(2n),
        &:not(:first-child) {
            margin-left: gutter();
        }
    }
}

.wp-block-button {
    color: inherit;

    &__link {
        @extend .c-btn;
        @extend .c-btn.-ghost;

        &::after {
            @extend .c-btn__icon;
            $color-icon: color(text, base);
            background: {
                color: color(gray, 300);
                image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='#{$color-icon}' d='M8.667 3.333h-1.333v4h-4v1.333h4v4h1.333v-4h4v-1.333h-4z'/%3E%3C/svg%3E");
                origin: content-box;
                position: center;
                repeat: no-repeat;
                size: 22px;
            };
            content: '';
            display: inline-block;;
            margin-left: .5em;
        }

        &:hover {
            &::after {
                background: {
                    color: color(primary, base);
                    image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='#ffffff' d='M8.667 3.333h-1.333v4h-4v1.333h4v4h1.333v-4h4v-1.333h-4z'/%3E%3C/svg%3E");
                }
                fill: #fff;
            }
        }
    }
}