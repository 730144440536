////
/// ============================================================================
/// FOOTER
/// ============================================================================
///
/// @group templates
////

.o-footer {
    &__sitemap {
        position: relative;
        @include media(">=l") {
            display: flex;
            flex-wrap: wrap;
            margin-left: -2em;
            margin-right: -2em;
        }
    }

    &__copyright {
        color: color(text, light);
        text-align: center;
        text-transform: uppercase;
        @include media(">=l") {
            text-align: left;
        }
    }
}


/**
 * Sitemap
 */
.c-sitemap {
    width: 100%;
    @include media(">=l") {
        margin-left: 2em;
        margin-right: 2em;
        width: auto;
    }

    &:not(:first-of-type) {
        border-top: 1px solid color(gray, 300);
        @include media(">=l") {
            border-top: none;
        }
    }

    &:not(.c-dropdown) {
        border-top: 0;
        margin-top: 2rem;
        text-align: center;
        @include media(">=l") {
            margin-top: 0;
            text-align: left;
        }

        h5 {
            display: none;
            @include media(">=l") {
                display: block;
            }
        }
    }

    label {
        border: none;
        padding: 1rem 0;
        @include media(">=l") {
            padding: 0;
        }

        svg {
            right: 0;
            @include media(">=l") {
                display: none;
            }
        }
    }

    &__title {
        color: color(text, base);
        margin-bottom: 0;
        margin-top: 0;
        text-transform: uppercase;
        @include media(">=l") {
            margin-bottom: 1rem;
        }
    }

    &__list {
        border: none;
        padding: 1rem 0;
        @include media(">=l") {
            display: block;
            padding: 0;
        }

        li {
            line-height: 1.6;
        }

        &.-cols {
            @include media(">=s") {
                column-count: 2;
            }
        }

        a {
            color: color(text, base);
            text-decoration: none;

            &:hover {
                color: color(primary, base);
            }
        }
    }
}


/**
 * Follow us
 */
.c-followus {
    display: inline-block;
    margin: 0 auto;
    position: relative;
    @include media(">=m") {
        flex: 0 1 auto;
    }

    &__more {
        @include absolute(bottom 130% right 4px, 4);
        background: #fff;
        border: 1px solid color(gray, 300);
        border-radius: $border-radius;
        box-shadow: 0 1px 8px rgba(#000, 0.1), 0 8px 24px rgba(#000, 0.15);
        opacity: 0;
        padding: 20px;
        text-align: left;
        transform: translateY(0);
        transition: visibility 0s .2s, opacity .2s, transform .2s ease-out;
        visibility: hidden;
        @include media(">=m") {
            min-width: 380px;
        }

        // Triangle
        &::before,
        &::after {
            @include triangle(color(gray, 300), 10px, 20px, bottom);
            @include absolute(right 5px bottom -10px);
            content: '';
        }

        &::after {
            border-top-color: #fff;
            bottom: -9px;
        }

        &.is-visible {
            opacity: 1;
            transform: translateY(0);
            transition: opacity .2s, transform .2s ease-out;
            visibility: visible;
        }

        .c-social {
            display: block;
            @include media(">=m") {
                display: flex;
                &__item {
                    flex: 1 1 50%;
                }
            }
        }
    }
}


/**
 * Copyright
 */
.c-copyright {
    @include media(">=l") {
        display: flex;
        flex-wrap: wrap;
    }

    &__left {
        @include media(">=l") {
            flex: 1;
        }
    }

    &__right {
    }

    a {
        text-decoration: none;
    }
}