////
/// ============================================================================
/// AMAZON AFFILIATES
/// ============================================================================
///
/// @group vendors
////

.aawp {
    @extend %type-regular;

    .aawp-product--horizontal {
        .aawp-product__title {
            text-decoration: none;
        }

        .aawp-product__footer {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            margin-top: 1em;
        }

        .aawp-product__pricing {
            margin: 0 2em 0 0;
        }
    }
}
