////
/// ============================================================================
/// WOOCOMMERCE / Table
/// ============================================================================
///
/// @group woocommerce
////



table.shop_table {
	@include fluid-type(font-size, 18px, 22px);
	border-collapse: separate;
	border-spacing: 0;
	margin: 0 0 2rem;
	width: 100%;

	th,
	td {
		padding: 1rem !important;
		text-align: left;
		vertical-align: top;

		p:last-child {
			margin-bottom: 0;
		}
	}

	th {
		color: color(text, light);
		font-size: 0.65rem;
		font-weight: $font-weight-medium;
		text-transform: uppercase;
	}

	thead {
		display: none;
		@include media(">=m") {
			display: table-header-group;
		}

		th {
			border-bottom: 2px solid color(gray, 500);
			vertical-align: middle;

			&:last-child {
				text-align: right;
			}
		}
	}

	tbody {
		h2 {
			font-size: 1em;
			font-weight: 400;
			letter-spacing: normal;
		}

		p {
			font-size: inherit;
			margin: 0;
		}
	}

	a {
		text-decoration: none;

		&.remove {
			align-items: center;
			display: flex;
			justify-content: center;
		}
	}

	.qty {
		min-width: 80px;
	}

	// Allineamento prezzi a destra
	td:last-child,
	th + td {
		text-align: right !important;
	}

	@include media("<m") {
		td.product-price,
		td.product-quantity {
			text-align: right;
		}
	}

	form {
		margin: 0;
	}


	&.cart {
		tbody {
			th {
				display: none;
				@include media(">=m") {
					display: table-cell;
				}
			}

			td {
				clear: both;
				display: block;

				@include media(">=m") {
					display: table-cell;

					&::before {
						display: none
					}
				}

				&::before {
					@include fluid-type(font-size, 18px, 22px);
					color: color(text, light);
					content: attr(data-title) ": ";
					float: left;
					font-weight: $font-weight-medium;
					padding-right: 1em;
					text-transform: uppercase;
				}

				&.product-remove {
					&::before {
						display: none;
					}
				}

				&.product-name {
					text-align: left !important;
					&::before {
						float: none;
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
}
