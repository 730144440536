////
/// ============================================================================
/// COMPONENT / Logo
/// ============================================================================
///
/// @group components
////

.c-logo {
    align-items: center;
    color: #fff;
    display: flex;
    margin: 0;
    overflow: hidden;
    svg {
        height: 100%;
        width: 100%;
    }

    &.-header {
        svg {
            max-height: 50px;
            max-width: 50px;
            @include media(">=m") {
                max-height: 70px;
                max-width: 220px;
            }
        }

        .c-logo__default {
            display: none;
            @include media(">=m") {
                display: block;
            }
        }
        .c-logo__symbol {
            display: block;
            @include media(">=m") {
                display: none;
            }
        }
    }

    &.-footer {
        max-height: 64px;
        max-width: 200px;
    }
}