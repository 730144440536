////
/// ============================================================================
/// HOME
///
/// 01. Hero
/// 02. Products
/// 03. Insights
/// ============================================================================
///
/// @group templates
////

/**
 * Hero
 */
.c-hero {
    position: relative;
    a {
        color: inherit;
    }

    &__wrap {
        align-items: center;
        display: flex;

        @include media(">=m") {
            min-height: 70vh;
        }
    }

    &__content {
        position: relative;
        z-index: 1;

        h1, .h4, ul {
            color: #fff;
            text-shadow: 0 0 14px rgba(#000, 0.3);
        }

        h1, .h4 {
            @include media(">=xl") {
                max-width: 80%;
            }
        }
    }

    &__image {
        filter: saturate(0.8);
        z-index: 0;

        &::before {
            @include absolute(top 0 left 0, 1);
            background: linear-gradient(
                    to bottom,
                    hsla(330, 0%, 11%, 0.45),
                    ease-in-out,
                    hsla(330, 0%, 11%, 0.65)
            ); // https://larsenwork.com/easing-gradients/
            content: '';
            display: block;
            height: 100%;
            width: 100%;
        }
    }
}