////
/// ============================================================================
/// PAGE
/// ============================================================================
///
/// @group templates
////

.o-page {
	&__header {
		p {
			color: color(text, light);

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&.-reduced {
			@include media(">=l") {
				width: span(8);
			}
		}

		&.-center {
			margin-left: auto;
			margin-right: auto;
		}

		.-post & {
			@include media(">=l") {
				max-width: span(8 of 10);
			}
		}

		.-full & {
			@include media(">=m") {
				width: span(10 of 12);
			}
		}
	}

	&__article {
		@include media(">=l") {
			margin-left: auto;
			margin-right: auto;
			max-width: span(10);
		}
	}

	&__body {
		@include media(">=m") {
			margin-left: auto;
			margin-right: auto;
			width: span(8);
		}

		@include media(">=l") {
			width: span(6 of 10);

			h2.has-text-align-center {
				margin-left: auto;
				margin-right: auto;
				width: span(4 of 8);

				.-post & {
					width: span(4 of 6);
				}
			}
		}

		.-full & {
			@include media(">=m") {
				width: span(10 of 12);
			}
		}
	}

	&__aside {
		@include media(">=m") {
			margin-left: auto;
			margin-right: auto;
			width: span(8);
		}
		@include media(">=l") {
			width: span(6);
		}

		h3 {
			@extend %type-regular;
		}
	}
}


/**
 * Contenuto del post
 */
.o-content {
	p, ul, ol {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		text-decoration: underline;
	}

	.-post & {
		h2:not([class*="elementor"]) {
			@extend %h3;
			@extend %type-regular;

			&:not(:last-child) {
				@include fluid-type(margin-bottom, 1rem, 2rem);
			}

			&:not(:first-child) {
				@include fluid-type(margin-top, 3rem, 4rem);
			}
		}

		h3:not([class*="elementor"]) {
			@extend %h4;

			&:not(:first-child) {
				@include fluid-type(margin-top, 2rem, 3rem);
			}
		}
	}

	//& > *[class^="wp-block-"] {
	//    &:not(:last-child) {
	//        @include fluid-type(margin-bottom, 2rem, 4rem);
	//    }
	//
	//    &:not(:first-child) {
	//        @include fluid-type(margin-top, 2rem, 4rem);
	//    }
	//}

	.wp-block-image figcaption {
		color: color(text, light);
		font-size: 90%;
		margin-bottom: 0;
		margin-top: 1rem;
		padding: 0 1em;
	}

	.alignwide {
		@include media(">=m") {
			left: 0;
			margin-left: 50%;
			transform: translateX(-50%);
			width: (100% + span(2 of 8) + gutter(8));
		}

		.-post & {
			@include media(">=m") {
				width: (100% + span(2 of 8) + gutter(8));
			}
			@include media(">=l") {
				width: (100% + span(2 of 6) + gutter(6));
			}
		}

		&:not(.wp-block-gallery) {
			& > * {
				width: 100%;
				// Nel caso ci siano elementi con position absolute interni all'elemento con transform: translateX(-50%);
				// come nel caso del pin di Pinterest nelle immagini, questa tecnica elimina il problema dell'allieamento.
				// @link https://codepen.io/anon/pen/jEveZq
				@include media(">=m") {
					left: 0;
					margin-left: -50%;
					position: relative;
					top: 0;
					transform: translateX(50%);
				}
			}

			iframe {
				height: auto;
				position: static;
			}
		}
	}

	.alignfull {
		left: calc(-1 * (100vw - 100%) / 2);
		position: relative;
		width: 100vw;

		& > * {
			width: 100%;
		}
	}

	img.aligncenter {
		display: block;
		margin: auto;
	}
}


.o-related {
	h5 {
		color: color(gray, 400);
		text-transform: uppercase;
	}

	.c-entry {
		@include fluid-type(margin-top margin-bottom, 0.5rem, 1rem);
	}
}


.wp-embed-responsive .wp-embed-aspect-16-9 .wp-block-embed__wrapper {
	aspect-ratio: 16 / 9;

	&::before {
		display: none;
	}
}
