////
/// ============================================================================
/// BASE / Typography
/// ============================================================================
///
/// @group base
////

/**
 * Links
 */
a {
	color: $link-color;
	text-decoration: $link-decoration;
	transition: all 0.25s;
	transition-timing-function: cubic-bezier(0, 0, 0.58, 1);

	&:hover {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}
}


/**
 * Headings
 */
h1, h2, h3, h4, h5, h6,
%h3, %h4 {
	color: color(text, dark);
	line-height: 1.3;
	margin: 0 0 1rem 0;
}

h1 {
	@include fluid-type(font-size, 34px, 60px);
	line-height: 1.2;
}

h2 {
	@include fluid-type(font-size, 30px, 50px);
	line-height: 1;
}

%h3, h3, .h3 {
	@include fluid-type(font-size, 26px, 40px);
}

%h4, h4, .h4 {
	@include fluid-type(font-size, 18px, 22px);
}

h5,
h6 {
	@include fluid-type(font-size, 14px, 16px);
}

.u-type {
	&.-xs {
		font-size: 12px;
		letter-spacing: 0.05em;
		line-height: 1.2;
	}

	&.-s {
		@include fluid-type(font-size, 14px, 16px);
		line-height: 1.2;
	}

	&.-m {
	}

	&.-l {
		@include fluid-type(font-size, 22px, 30px);
		line-height: 1.2;
	}
}


/**
 * Standard
 */
blockquote,
dl,
form,
ol,
p,
pre,
table,
ul {
	margin-bottom: 1rem;
	margin-top: 1rem;
}

ul, ol {
	padding-left: 1em;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

strong {
	font-weight: $font-weight-bold;
}


/**
 * Navigation
 */
nav {
	ul, ol {
		@include reset(list);
	}

	a, a:hover {
		text-decoration: none;
	}
}


/**
 * Citation
 */
blockquote {
	margin-left: 0;
	margin-right: 0;
	padding: 0 0 0 1rem;
	position: relative;

	@include media(">=l") {
		padding-left: 2rem;
	}

	&::before {
		@include absolute(top 0 left 0);
		background: color(primary, base);
		content: '';
		display: block;
		height: 100%;
		width: 8px;
	}

	p {
		&:first-of-type {
			margin-top: 0;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}


/**
 * Address
 */
address {
	font-style: inherit;
}


/**
 * Hr
 */
hr {
	border: 0;
	border-top: 1px solid #ccc;
	display: block;
	height: 1px;
	margin: 1em 0;
	padding: 0;
}
