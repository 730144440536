
.woocommerce-store-notice,
p.demo_store {
	width: 100%;
	background-color: #2C2C2C;
	position: relative;
	text-align: left;

	div {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	span {
		padding-right: 1em;
	}

	a.woocommerce-store-notice__dismiss-link {
		background-color: #000;
		color: #FAFAFA;
		display: block;
		flex-shrink: 0; // tende a rimpicciolirsi se il testo a sinistra è troppo lungo
		float: right;
		max-width: none;
		text-align: right;
		text-decoration: none;
		text-overflow: unset;

		&:hover {
			background-color: #FAFAFA;
			color: #2C2C2C;
		}
	}
}
