////
/// ============================================================================
/// LAZYLOAD
///
/// 01. Configuration
/// 02. Spinner
/// ============================================================================
///
/// @group vendors
////

/**
 * Configuration
 */
.lazyload {
    opacity: 0;
    position: relative;
    //.no-js & {
    //    display: none;
    //}
    &.loading {
        //background: 50% 50% url("images/loading.gif") no-repeat;
        opacity: 1;
        transition: opacity 300ms;
    }
    &.loaded {
        opacity: 1;
    }
}

.has-media-loading {
    min-height: 32px;
    position: relative;
    &::after {
        @include center(center, 32px, 32px);
        @extend .c-spinner;
        content: '';
        opacity: 1;
        transition: opacity 300ms;
    }
}
.has-media-loaded {
    position: relative;
    &::after {
        opacity: 0;
    }
}

img[sizes="auto"]:not([width]),
img[data-sizes="auto"]:not([width]) {
    display: block;
    width: 100%;
}