////
/// ============================================================================
/// WOOCOMMERCE / Checkout
/// ============================================================================
///
/// @group woocommerce
////

// Layout
.woocommerce-checkout {
	.woocommerce-info {
		.showlogin {
			@extend %c-btn;
			float: right;
		}
	}

	.woocommerce {
		display: grid;
		gap: var(--grid-gap);
		grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));

		&::before,
		&::after {
			display: none; // Reset WooCommerce Style
		}

		.clear {
			display: none;
		}

		> [class*="woocommerce-"] {
			float: none; // Reset WooCommerce Style
			grid-column: span var(--grid-columns);
			width: auto; // Reset WooCommerce Style
		}

		form {
			margin: 0;
		}

		h3 {
			@extend %h4;

			&:not(:first-child) {
				margin-top: 2rem;
			}
		}
	}

	.woocommerce-form-login {
		align-items: flex-end;
		display: grid;
		gap: calc(var(--grid-gap) / 2);
		grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));

		&::before,
		&::after {
			display: none; // Reset WooCommerce Style
		}

		.clear {
			display: none;
		}

		p {
			grid-column: span var(--grid-columns);
		}

		[class*="form-row"] {
			float: none; // Reset WooCommerce Style
			grid-column: span var(--grid-columns);
			width: auto; // Reset WooCommerce Style

			@include media(">=m") {
				--grid-columns: 4;
			}

			&::before,
			&::after {
				display: none; // Reset WooCommerce Style
			}
		}

		.form-row-first,
		.form-row-last {
			@include media(">=s", "<m") {
				--grid-columns: 4;
			}
		}
	}
}

form.woocommerce-checkout {
	display: contents;

	#billing_country_field strong {
		//@extend %form-field-input;
	}

	> [class*="woocommerce-"] {
		float: none; // Reset WooCommerce Style
		grid-column: span var(--grid-columns);
		width: auto; // Reset WooCommerce Style
	}

	[type="radio"],
	[type="checkbox"] {
		margin: 0 0.5em 0 0;
		position: static;
	}
}

#customer_details,
#checkout_review {
	grid-column: span var(--grid-columns);

	@include media(">=m") {
		--grid-columns: 6;
	}
}

// Customer Details
#customer_details {
	[class^="col-"],
	[class*="form-row"] {
		float: none; // Reset WooCommerce Style
		width: auto; // Reset WooCommerce Style

		&::before,
		&::after {
			display: none; // Reset WooCommerce Style
		}
	}

	[class^="col-"] {
		&:not(:first-child) {
			margin-top: 2rem;
		}

		> [class*="woocommerce-"] {
			&:not(:last-child) {
				margin-bottom: 2rem;
			}
		}
	}

	[class*="field-wrapper"],
	div.create-account {
		display: grid;
		gap: calc(var(--grid-gap) / 2);
		grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
	}

	[class^="form-row"] {
		grid-column: span var(--grid-columns);

		&:not(.form-row-wide):not(.notes) {
			@include media(">=s") {
				--grid-columns: 4;
			}
			@include media(">=m") {
				--grid-columns: 3;
			}
		}
	}

	p.create-account {
		margin-bottom: 1rem;
	}
}

// Checkout Review
#checkout_review {
	.form-row,
	.button {
		margin-top: 1rem;
	}
}


// Payment
.woocommerce-checkout #payment {
	background: transparent;

	ul.payment_methods {
		border-bottom: 0;
		margin: 0;
		padding: 0;

		li {
			align-items: center;
			background: color(gray, 200);
			display: flex;
			flex-wrap: wrap;
			padding: 1rem;

			&:not(:first-child) {
				margin-top: 1px;
			}
		}
	}

	input {
		flex-shrink: 1;
	}

	label {
		cursor: pointer;
		display: block;
		flex: 1 1 auto;
		margin: 0;
		padding: 0.5rem 0;
		position: relative;
	}

	div.payment_box {
		background: #fff;
		flex: 1 1 auto;
		margin: 1rem -1rem -1rem -1rem;
		padding: 1rem;
		width: 100%;

		&::before {
			display: none;
		}
	}
}

// Shipping methods
.woocommerce {
	table.shop_table.hmk_woocommerce-checkout-shipping {
		margin-top: 1em;
	}

	tr.woocommerce-shipping-totals.shipping th {
		white-space: nowrap;
	}

	ul#shipping_method {
		text-align: left;

		li {
			align-items: flex-start;
			display: flex;
			margin-bottom: 1em;

			input {
				margin: 2px 0.5em 0 0; // reset
				vertical-align: middle; // reset
			}

			label {
				line-height: 1.3;
				margin: 0; // reset
				width: 100%;

				select {
					margin-top: 0.5rem;
				}
			}
		}

		#ym-discl {
			margin: 1em;
			padding: 0;

			label {
				display: flex;

				input {
					flex-shrink: 0;
				}
			}
		}
	}
}

/**
 * Custom Select 2
 */
.select2-container--default {
	height: 42px;

	.select2-selection--single {
		border: none;

		.select2-selection__rendered {
			background-color: #fff;
			border: 1px solid #ccc;
			border-radius: 3px;
			line-height: 42px;
			padding: 0 0.5rem;
		}

		.select2-selection__arrow {
			height: 42px;
		}
	}
}

.invoice-info {
	grid-column: span var(--grid-columns);
	margin-top: 2rem;
}

#invoice_profile-company {
	display: grid;
	gap: calc(var(--grid-gap) / 2);
	grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));

	[class^="form-row"] {
		grid-column: span var(--grid-columns);

		&:not(.form-row-wide):not(.notes) {
			@include media(">=s") {
				--grid-columns: 4;
			}
			@include media(">=m") {
				--grid-columns: 3;
			}
		}
	}
}


#add_payment_method #payment div.form-row,
.woocommerce-cart #payment div.form-row,
.woocommerce-checkout #payment div.form-row {
	padding: 0;
}
