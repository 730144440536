////
/// ============================================================================
/// COMPONENT / Dropdown
/// ============================================================================
///
/// @link https://codyhouse.co/gem/css-multi-level-accordion-menu/
///
/// @group components
////

.c-dropdown {
    padding: 0;
    &__checkbox {
        opacity: 0;
        position: absolute;
        &:checked + label + .c-dropdown__panel,
        &:checked + label:nth-of-type(n) + .c-dropdown__panel {
            display: block; // use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors
        }
        @at-root &:checked + label svg {
            transform: rotate(-180deg);
        }
    }
    &__label {
        border: 1px solid color(gray, 300);
        color: color(text, base);
        cursor: pointer;
        display: block;
        font-size: inherit;
        font-weight: inherit;
        margin: 0;
        padding: 1rem;
        position: relative;

        svg {
            @include center(right 1em, 24px, 24px, 1);
            transition: all .5s;
        }
    }
    &__panel {
        display: none;
        list-style: none;
        margin: -1px 0 0 0;
        padding: 0;
        border: 1px solid color(gray, 300);
    }
}