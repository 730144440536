////
/// ============================================================================
/// COMPONENT / Video
/// ============================================================================
///
/// @group components
////

.c-video {
    background: #000;
    position: relative;
    width: 100%;

    &__play {}

    &__image {
        cursor: pointer;
        img {
            opacity: 0.5;
        }
    }
}


/**
 * Play
 */
.c-play {
    @extend %reset-btn;
    @include center(center, 40px, 40px, 10);
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    text-align: center;
    transform: translateY(0) scale(1);
    transition: transform 0.9s ease 0.35s, opacity 0.9s ease 0.35s;
    svg {
        height: 20px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate3d(-42%, -50%, 0);
        transition: color .3s ease;
    }
    &::before {
        background-color: rgba(#000, .3);
        border-color: #fff;
        border-radius: 100%;
        border-style: solid;
        border-width: 2px;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: background-color .3s ease, transform .3s ease, border-color .3s ease;
        width: 100%;
    }
    &:hover {
        color: color(secondary, base);
        &::before {
            background-color: rgba(#000, .8);
            border-color: color(secondary, base);
            transform: scale(1.2);
        }
    }

    &.-large {
        height: 60px;
        margin-left: -30px;
        margin-top: -30px;
        width: 60px;
        svg {
            height: 20px;
            width: 20px;
        }
        @include media(">=m") {
            height: 80px;
            margin-left: -40px;
            margin-top: -40px;
            width: 80px;
            &::before {
                border-width: 3px;
            }
            svg {
                height: 34px;
                width: 34px;
            }
        }
        @include media(">=l") {
            height: 120px;
            margin-left: -60px;
            margin-top: -60px;
            width: 120px;
            svg {
                height: 42px;
                width: 42px;
            }
        }
    }
}


.c-playlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &__item {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        padding: 0;
        width: 100%;
        @include media(">=m") {
            flex-direction: column;
            max-width: 200px;
            padding: 0 1em;
        }
        div {
            flex: 0 0 auto;
            width: 30%;
            @include media(">=m") {
                width: 100%;
            }
        }
        figcaption {
            flex: 1;
            text-align: left;
            padding-left: 1em;
            word-break: break-word;
            @include media(">=m") {
                padding-left: 0;
                text-align: center;
            }
        }
    }
}
