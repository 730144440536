////
/// ============================================================================
/// COMPONENT / Widget
/// ============================================================================
///
/// @group components
////

.c-widget {
    height: auto;
    width: 100%;
    &__title {
        display: block;
        h2 {
            color: color(primary, base);
        }
    }
    &__content {
        @include clearfix;
    }

    &.-toc {
        display: none;
        @include media(">=l") {
            display: block;
        }
    }
}