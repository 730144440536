////
/// ============================================================================
/// UTILITY / Align
/// ============================================================================
///
/// @group utilities
////

/**
 * Text
 */
.u-text-left,
.alignleft {
    text-align: left;
}

.u-text-right,
.alignright {
    text-align: right;
}

.u-text-center,
.aligncenter {
    text-align: center;
}


.u-fullwidth {
    left: calc(-1 * (100vw - 100%) / 2);
    position: relative;
    width: 100vw;
}