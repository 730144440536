/* --------------------------------

File#: _1_adaptive-navigation
Title: Adaptive Navigation
Descr: Navigation where the number of visible items changes according to the available space
Usage: codyhouse.co/license

-------------------------------- */

.c-subnav {
    position: relative;
    z-index: 98;
}

.adapt-nav {
    @extend %type-bold;
    font-size: 13px;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    position: relative;
    z-index: 2;
    @include media(">=s") {
        font-size: 14px;
    }
    @include media(">=m") {
        font-size: 15px;
    }

    &__list {
        align-items: center;
        display: flex;
        position: relative;
    }

    &__item {
        display: inline-block; // flex fallback
        flex-shrink: 0;
        margin-right: 0;

        &.is-hidden {
            display: none;
        }
    }

    &__link {
        @include fluid-type(padding-right padding-left, 0.5em, 1.4em);
        align-items: center;
        border-radius: $border-radius;
        color: color(text, light);
        display: flex;
        height: 100%;
        letter-spacing: -0.02em;
        padding-bottom: 0.5em;
        padding-top: 0.5em;
        text-decoration: none;

        &:hover {
            color: color(text, dark);
        }

        .is-active & {
            color: color(primary, base);
        }

        &.-home {
            color: color(text, dark);
            @include media("<m") {
                padding-left: 0;
                padding-right: 1em;
            }
        }

        svg {
            @include fluid-type(font-size, 2em, 2.5em);
            margin-right: 0.2em;
        }
    }

    &__item--hidden { // used to hide the 'More Links' button
        visibility: hidden;
    }
}

.js {
    .adapt-nav {
        opacity: 0; // hide navigation while JS initializes nav

        &--is-visible {
            opacity: 1;
        }

        &__item--more { // list item containing 'More Links' button
            @include transform(y);
            margin-right: 0;
            right: 0;
        }

        &__btn {
            @extend %reset-btn;
            align-items: center;
            border-radius: 50%;
            display: flex;
            font-size: 1.5em;
            height: 2em;
            justify-content: center;
            width: 2em;

            &:hover {
                background-color: color(gray, 200);
            }

            &:active {
                background-color: color(gray, 300);
            }
        }

        &__dropdown { // dropdown with hidden items
            background-color: #fff;
            border-radius: $border-radius;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.085), 0 1px 8px rgba(0, 0, 0, 0.1);
            opacity: 0;
            padding: 0.375em 0;
            position: absolute;
            right: 0;
            top: calc(100% + 4px);
            transition: visibility 0s 0.2s, opacity 0.2s;
            user-select: none;

            // hide dropdown
            visibility: hidden;
            width: 240px;
            z-index: 5;
        }

        &__dropdown--is-visible {
            opacity: 1;
            transition: visibility 0s, opacity 0.2s;
            visibility: visible;
        }

        &__dropdown {
            // optional - reset spacing and text units - no longer affected by em units
            // @include spaceUnit(1rem);
            // @include textUnit(1rem);

            .adapt-nav__item {
                display: block;
                margin: 0;
            }

            .adapt-nav__link {
                border-radius: 0;
                overflow: hidden;
                padding: 0.375em 0.75em;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:hover {
                    background-color: color(gray, 200);
                }
            }
        }
    }
}

html:not(.js) { // nav style if JS is disabled
    .adapt-nav {
        display: flex;
        justify-content: center;
        overflow: hidden;

        &__list {
            justify-content: left;
            overflow: auto;
        }
    }
}