////
/// ============================================================================
/// OBJECT / Container
/// ============================================================================
///
/// @group objects
////

.o-container {
    padding-left: $container-side;
    padding-right: $container-side;
    position: relative; // .c-organic
    width: 100%;

    &__wrap {
        @if ($debug == true) {
            background: susy-svg-grid() no-repeat scroll
        }

        margin-left: auto;
        margin-right: auto;
        max-width: $container;

        &.-expand {
            @include media(">=l") {
                max-width: $container + 200px;
            }
        }
    }
}