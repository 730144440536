////
/// ============================================================================
/// COMPONENT / Accordion
/// ============================================================================
///
/// @group components
////

.c-accordion {
    border-top: 1px solid color(gray, 300);
    list-style: none;
    padding-left: 0;
    &__item {
        border: 0 solid color(gray, 300);
        border-bottom-width: 1px;

        &:last-child {
            border-top-width: 0;
        }

        &.is-open > .c-accordion__header > .c-accordion__header-icon {
            color: color(primary, base);
        }
    }

    &__header {
        appearance: none;
        background: transparent;
        border: 0;
        border-radius: 0;
        color: color(text, base);
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        width: 100%;

        &[type="button"]:hover {
            color: color(text, base);
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }
        &-title {
            @extend %type-bold;
            color: currentColor;
            flex: 1;
            text-align: left;
        }
        &-icon  {
            cursor: pointer;
            display: none;
            float: right; // flex fallback
            flex-shrink: 0;
            height: 1em;
            margin-right: 1em;
            width: 1em;

            svg {
                height: 100%;
                transform-origin: center;
                width: 100%;
            }
        }
    }

    &__panel {
        &-content {
            padding-left: 2.3em; // Icon Width
            padding-bottom: 1.5rem;
            p {
                &:first-of-type {
                    margin-top: 0;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}


.js {
    .c-accordion {
        &__header-icon {
            display: block;
        }

        &__panel {
            display: none;
            overflow: hidden;
            will-change: height;
            transform: translateZ(0);
        }

        &__item {
            &.is-open {
                .c-accordion__header {
                    color: color(primary, base);
                    svg {
                        transform: rotate(90deg);
                    }
                }
                & > .c-accordion__panel {
                    display: block;
                }
            }
        }
    }
}