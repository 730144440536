////
/// ============================================================================
/// GRAVITY FORMS / Alert
/// ============================================================================
///
/// @group vendors
////

body {
    .gform_wrapper.gravity-theme {
        .gform_validation_errors {
            @extend %c-alert;
            @extend .c-alert.-error;
            margin-bottom: 1rem;
            margin-top: 0;

            & > h2 {
                color: darken(color(error), 30%);
                font-size: 1rem;
                font-weight: 500;
                line-height: 1rem;
                margin: 0;
            }

            & > ol {
                margin: 0;

                li {
                    color: color(text, dark);
                    font-size: 0.5rem;
                    line-height: 1.5rem;
                    list-style-type: none!important;
                    margin: 0;

                    a {
                        color: color(text, dark);
                        font-size: 0.5rem;
                    }
                }
            }
        }

        .gfield_validation_message,
        .gform_wrapper .validation_message {
            @extend .c-alert.-error;
            font-size: 0.5rem;
            padding: 0.5rem;
        }

        .gform-icon {
            display: none;
        }
    }
}
