////
/// ============================================================================
/// COMPONENT / Form
/// ============================================================================
///
/// Form by Locomotive Boilerplate
/// @see https://hopdeco.ca/en/dashboard/checkout/
///
/// @example
///
/// <form class="c-form">
///     <div class="c-form__item has-error">
///         <label class="c-label" for="input_name">Field Label *</label>
///         <input class="c-input" type="text" id="input_name" required>
///         <p class="c-error" aria-hidden="true">Error message</p>
///     </div>
///     <div class="c-form__item">
///         <label class="c-label" for="select_name">Field Label *</label>
///         <select class="c-select" id="select_name" required>
///             <option selected>Select option</option>
///             <option>Option One</option>
///             <option>Option Two</option>
///         </select>
///     </div>
///     <div class="c-form__item">
///         <input class="c-radio" type="radio" id="radio_name">
///         <label class="c-radio__label" for="radio_name">Radio</label>
///     </div>
///     <div class="c-form__item">
///         <input class="c-checkbox" type="checkbox" id="checkbox_name">
///         <label class="c-checkbox__label" for="checkbox_name">Checkbox</label>
///     </div>
///     <div class="c-form__item">
///         <label class="c-label" for="textarea_name">Field Label *</label>
///         <textarea class="c-textarea" id="textarea_name"></textarea>
///     </div>
/// </form>
///
/// @group components
////
.c-form {
    .o-section__content & {
        margin-top: 0;
    }

    &__item {
        clear: both;
        margin-bottom: $form-item-margin;
        position: relative;

        &.-first,
        &.-last {
            @include media(">=s") {
                clear: none;
                float: left;
                width: calc(50% - #{1.5em / 2});
            }
        }

        &.-first {
            @include media(">=s") {
                margin-right: 1.5em;
            }
        }

        &.-left {
            clear: right;
        }
    }

    .c-btn {
        @include relative(left 50%);
        transform: translateX(-50%);
    }

    form {
        margin: 0;
    }
}

// Label
%c-label,
.c-label {
    color: $label-text-color;
    display: block;
    font-weight: $font-weight-regular;
    margin-bottom: rem(5px);
}

// Input
%c-input,
.c-input {
    appearance: none;
    background-color: $input-background-color;
    border: $input-border-width solid $input-border-color;
    border-radius: $border-radius;
    color: $input-text-color;
    padding: $input-padding;
    width: 100%;
    line-height: 1;

    &:focus {
        background-color: $input-background-color;
        border-color: darken($input-border-color, 10%);
    }

    &::placeholder {
        color: $input-placeholder-color;
    }

    .has-error & {
        border-color: color(error);
    }
}

// Checkbox
%c-checkbox,
.c-checkbox,
%c-radio,
.c-radio {
    opacity: 0;
    position: absolute;
    width: 0;

    &:focus {
        + label,
        .c-checkbox__label {
            &::before {
                border-color: $input-border-color;
            }
        }
    }

    &:checked {
        + label,
        .c-checkbox__label,
        .c-radio__label {
            &::after {
                opacity: 1;
            }
        }
    }

    + label,
    &__label {
        @extend %c-label;
        color: $input-text-color;
        display: inline-block;
        font-weight: $font-weight-regular;
        padding-left: ($checkbox + rem(10px));
        position: relative;

        &::before,
        &::after {
            @include absolute(top 0 left 0);
            border-radius: $border-radius;
            content: "";
            display: inline-block;
            height: $checkbox;
            padding: 0;
            width: $checkbox;
        }

        &::before {
            background-color: $input-background-color;
            border: 1px solid $input-border-color;
        }

        &::after {
            background-color: transparent;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='#{$checkbox-icon-color}' d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/%3E%3C/svg%3E");
            background-origin: content-box;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 90%;
            border-color: transparent;
            opacity: 0;
        }
    }
}

// Radio
%c-radio,
.c-radio {
    + label,
    &__label {
        &::before,
        &::after {
            border-radius: 50%;
        }

        &::after {
            background: $radio-icon-color;
            background-image: none;
            content: '';
            transform: scale(0.4);
        }
    }
}

// Select
%c-select,
.c-select {
    @extend %c-input;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='#{$select-icon-color}' d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3E%3C/svg%3E");
    background-origin: content-box;
    background-position: right -20px center;
    background-repeat: no-repeat;
    border-radius: $border-radius;
    padding-right: $select-icon;
    position: relative;
    z-index: 1;
}

// Textarea
%c-textarea,
.c-textarea {
    @extend %c-input;
    border-radius: $border-radius;
    min-height: rem(100px);
}

// Error
.c-error {
    bottom: 4px;
    color: color(error);
    font-size: 12px;
    line-height: normal;
    margin: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translate3d(0, 100%, 0);
    transition: opacity .3s cubic-bezier(.4, 0, 0, 1), transform .3s cubic-bezier(.4, 0, 0, 1);

    .has-error & {
        opacity: 1;
        transform: translate3d(0, $form-item-margin, 0);
    }
}
