////
/// ============================================================================
/// MIXINS
///
/// 01. Aspect Ratio ................ aspect-ratio()
/// 02. Clearfix .................... clearfix()
/// 03. Equal columns ............... equalcolumns()
/// 04. Hide elements ............... hide()
/// 05. Position .................... position()
/// 06. Position Absolute ........... absolute()
/// 07. Position Relative ........... relative()
/// 08. Position Fixed .............. fixed()
/// 09. Position Static ............. static()
/// 10. Position Center ............. center()
/// 11. Reset ....................... reset()
/// 12. Tilted ...................... tilted()
/// 13. Transform Align ............. transform()
/// 14. Triangle .................... triangle()
/// 15. Type ........................ type()
/// 16. Fluid Type .................. fluid-type()
/// 17. Line-height Crop ............ text-crop()
/// ============================================================================
///
/// @group abstracts
////

/// Aspect Ratio
///
/// @link http://goo.gl/eXv9ld
///
/// @param {array} $ratio [1 1] - 16 9 | 4 3
///
/// @example scss - Usage
/// .foo {
///    @include aspect-ratio(16 9);
/// }
///
/// @example css - CSS Output
/// .foo:before {
///     content: '';
///     display: block;
///     padding-bottom: 56.25%;
/// }
@mixin aspect-ratio($ratio: 1 1) {
    @if length($ratio) < 2 or length($ratio) > 2 {
        @warn "$ratio Deve essere una lista con 2 valori (es. 16 9).";
    }

    &:before {
        content: '';
        display: block;
        padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
    }
}


/// Clearfix
///
/// @link http://cssmojo.com/latest_new_clearfix_so_far/
///
/// @example scss - Usage
/// .foo {
///    @include clearfix;
/// }
///
/// @example css - CSS Output
/// .foo:after {
///     clear: both;
///     content: '';
///     display: table;
/// }
@mixin clearfix() {
    &:after {
        clear: both;
        content: '';
        display: table;
    }
}


/// Equal columns
///
/// @param {length} $items ['article']
///
/// @example scss - Usage
/// .foo {
///    @include equalcolumns('.bar');
/// }
///
/// @example css - CSS Output
/// .foo {
///     width: 100%;
///     overflow: hidden;
/// }
/// .foo .bar {
///     margin-bottom: -99999px !important;
///     padding-bottom: 99999px !important;
/// }
@mixin equalcolumns($items: 'article') {
    width: 100%;
    overflow: hidden;

    #{$items} {
        margin-bottom: -99999px !important;
        padding-bottom: 99999px !important;
    }
}


/// Hide elements
///
/// @param {String} $type [hidden] - Variabili: hidden | visuallyhidden | focusable | invisible
///
/// @example scss - Usage
/// .foo {
///    @include hide;
/// }
/// .bar {
///    @include hide(visuallyhidden);
/// }
///
/// @example css - CSS Output
/// .foo {
///     display: none !important;
///     visibility: hidden;
/// }
/// .bar {
///     border: 0;
///     clip: rect(0 0 0 0);
///     height: 1px;
///     margin: -1px;
///     overflow: hidden;
///     padding: 0;
///     position: absolute;
///     width: 1px;
/// }
@mixin hide($type: hidden) {
	@if $type == hidden {
		display: none !important;
		visibility: hidden;
	}
	@if $type == visuallyhidden {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
	@if $type == focusable {
		&:active, &:focus {
			clip: auto;
			height: auto;
			margin: 0;
			overflow: visible;
			position: static;
			width: auto;
		}
	}
	@if $type == invisible {
		visibility: hidden;
	}
}


/// Position
///
/// @link http://goo.gl/TTfZOx Hugo GIRAUDEL
///
/// @param {String} $position - Posizione: absolute | relative | fixed | static
/// @param {String} $args - Direzione: top right bottom left
/// @param {Number} $zindex [false] - Livello dello z-index
///
/// @example scss - Usage
/// .foo {
///    @include position(relative, top -10px right bottom left, 999);
/// }
///
/// @example css - CSS Output
/// .foo {
///     position: relative;
///     top: -10px;
///     right: 0;
///     bottom: 0;
///     left: 0;
///     zindex: 999;
/// }
@mixin position(
    $position,
    $args: (),
    $zindex: false
) {
    $offsets: top right bottom left;
    $zindex-value: type-of($zindex);
    
    position: $position;
    
    @if $zindex-value == number {
        z-index: $zindex;
    }
  
    @each $offset in $offsets {
        $index: index($args, $offset);
    
        @if $index {
            @if $index == length($args) {
                #{$offset}: 0;
            }
            @else {
                $next: nth($args, $index + 1);
                @if is-length($next) {
                    #{$offset}: $next;
                }
                @else if index($offsets, $next) {
                    #{$offset}: 0;
                }
                @else {
                    @warn "Invalid value '#{$next}' for offset '#{$offset}'.";
                }
            }
        }
    }
}


/// Position Absolute
///
/// @param {String} $args - Direzione: top right bottom left
///
/// @example scss - Usage
/// .foo {
///    @include absolute(top -10px left, 999);
/// }
///
/// @example css - CSS Output
/// .foo {
///     position: absolute;
///     top: -10px;
///     left: 0;
///     zindex: 999;
/// }
@mixin absolute(
    $args: (),
    $zindex: ()
) {
    @include position(absolute, $args, $zindex);
}


/// Position Relative
///
/// @param {String} $args - Direzione: top right bottom left
///
/// @example scss - Usage
/// .foo {
///    @include relative(top -10px left, 999);
/// }
///
/// @example css - CSS Output
/// .foo {
///     position: relative;
///     top: -10px;
///     left: 0;
///     zindex: 999;
/// }
@mixin relative(
    $args: (),
    $zindex: ()
) {
    @include position(relative, $args, $zindex);
}


/// Position Fixed
///
/// @param {String} $args - Direzione: top right bottom left
///
/// @example scss - Usage
/// .foo {
///    @include fixed(top -10px left, 999);
/// }
///
/// @example css - CSS Output
/// .foo {
///     position: fixed;
///     top: -10px;
///     left: 0;
///     zindex: 999;
/// }
@mixin fixed(
    $args: (),
    $zindex: ()
) {
    @include position(fixed, $args, $zindex);
}


/// Position Static
///
/// @param {String} $args - Direzione: top right bottom left
///
/// @example scss - Usage
/// .foo {
///    @include static(top -10px left, 999);
/// }
///
/// @example css - CSS Output
/// .foo {
///     position: static;
///     top: -10px;
///     left: 0;
///     zindex: 999;
/// }
@mixin static(
    $args: (),
    $zindex: ()
) {
    @include position(static, $args, $zindex);
}


/// Position Center
///
/// @param {String} $directions [center] - Direzione: center | top | right | bottom | left
/// @param {Number} $height [0] - Altezza elemento
/// @param {Number} $width [0] - Larghezza elemento
///
/// @example scss - Usage
/// .foo {
///    @include center(center, 50px, 50px, 999);
/// }
/// .bar {
///    @include center(top, false, 50px);
/// }
/// .baz {
///    @include center(right 1em, 50px, 50px);
/// }
///
/// @example css - CSS Output
/// .foo {
///     height: 50px;
///     left: 50%;
///     margin-left: -25px;
///     margin-top: -25px;
///     position: absolute;
///     top: 50%;
///     width: 50px;
///     zindex: 999;
/// }
/// .bar {
///     margin-left: -25px;
///     position: absolute;
///     top: 0;
///     width: 50px;
///     zindex: 999;
/// }
/// .baz {
///     height: 50px;
///     margin-top: -25px;
///     position: absolute;
///     right: 1em;
///     top: 50%;
///     width: 50px;
///     zindex: 999;
/// }
@mixin center(
    $directions: center,
    $height: false,
    $width: false,
    $zindex: false
) {
    $offsets: top right bottom left;
    $zindex-value: type-of($zindex);
    
    position: absolute;
    
    @if $zindex-value == number {
        z-index: $zindex;
    }
	
    @if $directions == center {
		left: 50%;
        height: $height;
		margin-left: -#{$width/2};
		margin-top: -#{$height/2};
		top: 50%;
        width: $width;
	}
    @else {
        @each $offset in $offsets {
            $index: index($directions, $offset);
        
            @if $index {
                @if $index == length($directions) {
                    #{$offset}: 0;
                }
                @else {
                    $next: nth($directions, $index + 1);
                    @if is-length($next) {
                        #{$offset}: $next;
                    }
                    @else if index($offsets, $next) {
                        #{$offset}: 0;
                    }
                    @else {
                        @warn "Invalid value '#{$next}' for offset '#{$offset}'.";
                    }
                }
            }
        }
    }
	@if nth($directions, 1) == top {
		left: 50%;
		margin-left: -#{$width/2};
        width: $width;
        @if $height {
            height: $height;
        }
	}
	@if nth($directions, 1) == right {
		margin-top: -#{$height/2};
		top: 50%;
        height: $height;
        @if $width {
            width: $width;
        }
	}
	@if nth($directions, 1) == bottom {
		left: 50%;
		margin-left: -#{$width/2};
        width: $width;
        @if $height {
            height: $height;
        }
	}
	@if nth($directions, 1) == left {
		margin-top: -#{$height/2};
		top: 50%;
        height: $height;
        @if $width {
            width: $width;
        }
	}
}


/// Reset
///
/// @param {String} $element [list] - Elementi: list | space
///
/// @example scss - Usage
/// .foo {
///    @include reset(list);
/// }
/// .bar {
///    @include reset(space);
/// }
///
/// @example css - CSS Output
/// .foo {
///     list-style: none;
///     margin-bottom: 0;
///     margin-top: 0;
///     padding-left: 0;
/// }
/// .foo li {
///     margin-left: 0;
/// }
/// .bar {
///     margin-bottom: 0;
///     padding-top: 0;
/// }
@mixin reset($element: list) {
    @if $element == list {
        list-style: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
        li {
            margin-left: 0;
        }
    }
    @if $element == space {
        margin-bottom: 0;
        margin-top: 0;
    }
}


/// Tilted
///
/// Apply a tilted effect by generating a pseudo-element with a diagonal
///
/// @parameter {Angle} $angle - the tilt angle
/// @parameter {Color} $color - the color to be used as background + gradient
/// @parameter {String} $position ['top'] - either `top` or `bottom`
/// @parameter {String} $pseudo ['before'] - either `before` or `after`
///
/// @link https://www.sitepoint.com/tilted-angles-in-sass/
/// @author Hugo Giraudel
@mixin tilted($angle, $color, $position: 'top', $pseudo: 'before') {
    $height: tilted-height($angle);

    position: relative;

    &::#{$pseudo} {
        content: '';
        padding-top: $height;
        position: absolute;
        left: 0;
        right: 0;
        @if ($position == 'top') {
            bottom: 100%;
            background-image: linear-gradient($angle, $color 50%, rgba($color, 0.1) 50.1%, transparent 50%);
        } @else {
            top: 100%;
            background-image: linear-gradient($angle, transparent 50%, rgba($color, 0.1) 50%, $color 50.1%);
        }
    }
}


/// Transform Align
///
/// @link http://goo.gl/GpnBGc
/// @link http://goo.gl/GpnBGc Smashing Magazine - Vertical Centering
///
/// @param {String} $direction [null] - Direzioni: null | x | y
/// @param {Number} $zindex [false] - Livello dello z-index
///
/// @example scss - Usage
/// .foo {
///     @include transform(null, 10);
/// }
///
/// @example css - CSS Output
/// .foo {
///     left: 50%;
///     position: absolute;
///     top: 50%;
///     transform: translateX(-50%) translateY(-50%);
///     z-index: 10;
/// }
@mixin transform(
    $direction: null,
    $zindex: false
) {
    $zindex-value: type-of($zindex);

    position: absolute;

    @if $direction == x {
        left: 50%;
        transform: translateX(-50%);
    }
    @if $direction == y {
        top: 50%;
        transform: translateY(-50%);
    }
    @if $direction == null {
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    @if $zindex-value == number {
        z-index: $zindex;
    }
}

/// Triangle
///
/// @link https://github.com/at-import/toolkit
///
/// @param {String} $element [list] - Elementi: list | space
///
/// @example scss - Usage
/// .foo {
///    @include triangle(#000, 10px, 10px, bottom right);
/// }
///
/// @example css - CSS Output
/// .foo {
///    border: 0 solid transparent;
///    border-bottom-color: #000;
///    border-width: 0 0 10px 10px;
///    display: block;
///    height: 0;
///    width: 0;
/// }
@mixin triangle(
    $color: null,
    $height: null,
    $width: null,
    $angle: null
) {
    $color: if($color != null, $color, #000);
    $height: if($height != null, $height, 1em);
    $width: if($width != null, $width, 1em);
    $angle: if($angle != null, $angle, 0);

    @if nth($angle, 1) == "top" or nth($angle, 1) == "bottom" {
        $angle: "#{$angle}";
    }
    @if $angle == "top" {
        $angle: 0;
    }
    @if $angle == "top right" {
        $angle: 45 * 7;
    }
    @if $angle == "right" {
        $angle: 45 * 2;
    }
    @if $angle == "bottom right" {
        $angle: 45 * 1;
    }
    @if $angle == "bottom" {
        $angle: 45 * 4;
    }
    @if $angle == "bottom left" {
        $angle: 45 * 3;
    }
    @if $angle == "left" {
        $angle: 45 * 6;
    }
    @if $angle == "top left" {
        $angle: 45 * 5;
    }

    @if unit($height) == "" or unit($height) == "%" {
        @debug "in triangle #{$height} is not a compatible unit for height."
    }

    @if unit($width) == "" or unit($width) == "%" {
        @debug "in triangle #{$width} is not a compatible unit for width."
    }

    // offset 45deg to make each side start at 0
    $deg: $angle + 45;
    // if units, remove units
    @if unit($deg) == deg {
        $deg: $deg / 1deg;
    }
    // shift to be on a scale from 0 to 90.
    @while $deg > 90 {
        $deg: $deg - 90;
    }
    @while $deg < 0 {
        $deg: $deg + 90;
    }
    // Get a ratio of 90 to multiply by.
    $deg: $deg / 90;
    
    // make sure metrics are reset
    display: block;
    width: 0;
    height: 0;
    border: 0 solid transparent;

    // run through sides
    @if $angle <= 45 or $angle > 315 {
        border-bottom-color: $color;
        border-width: 0 ($width * abs($deg - 1)) $height ($width * $deg);
    }
    @if $angle > 45 and $angle <= 135 {
        border-left-color: $color;
        border-width: ($height * $deg) 0 ($height * abs($deg - 1)) $width;
    }
    @if $angle > 135 and $angle <= 225 {
        border-top-color: $color;
        border-width: $height ($width * $deg) 0 ($width * abs($deg - 1));
    }
    @if $angle > 225 and $angle <= 315 {
        border-right-color: $color;
        border-width: ($height * abs($deg - 1)) $width ($height * $deg) 0;
    }
}


/// Type
///
/// @link https://zellwk.com/blog/viewport-based-typography/
/// @link https://www.smashingmagazine.com/2016/05/fluid-typography/
/// @link http://www.sassmeister.com/gist/7f22e44ace49b5124eec
/// @link http://codepen.io/MadeByMike/pen/bEEGvv
/// @link http://codepen.io/MadeByMike/pen/VvwqvW
@mixin type(
    $properties,
    $typesize: 'base',
    $min-mq: map-get($breakpoints, s),
    $max-mq: map-get($breakpoints, xl),
    $typesizes: $typesizes
) {
    @if type-of($typesizes) != 'map' {
        @error "Cannot find #{$typesizes} map found";
    }

    $_map: map-get($typesizes, $typesize);

    @if length($_map) == 2 {
        $min-base-font: nth(map-get($typesizes, 'base'), 1);
        $min-font: nth($_map, 1);
        $max-font: nth($_map, 2);

        @each $property in $properties {
            #{$property}: $min-font;
        }

        @include media(">=#{$min-mq}") {
            /**
             * 1. Apply rule to all browsers
             * 2. Override WebKit browsers, because Safari needs percentages to work
             * 3. Override IE/Edge, because Edge applies previous -webkit-calc() and
             *    won't work with percentages
             */
            @each $property in $properties {
                $rem-min-font: $min-font;
                $rem-max-font: $max-font;
                $dif-font: strip-units($rem-max-font) - strip-units($rem-min-font);
                $dif-mq: strip-units($max-mq) - strip-units($min-mq);

                #{$property}: calc(#{$rem-min-font} + #{$dif-font} * (100vw - #{$min-mq}) / #{$dif-mq}); /* 1 */
            }
        }

        @include media(">=#{$max-mq}") {
            @each $property in $properties {
                #{$property}: $max-font;
            }
        }
    }
    @else {
        @error "Specificare 2 valori per ciascuna chiave della mappa #{$typesizes}";
    }
}


/// Fluid Type
///
/// Magic calc + vh to allow text to be fluid between minimum
/// and maximum breakpoints.
///
/// @group typography
/// @param {variable} $properties [font-size] - Property
/// @param {variable} $min-value [12px] - Minimum font size
/// @param {variable} $max-value [24px] - Maximum font size
/// @param {variable} $min-vw [420px] - Stop scaling font smaller at this screen resolution
/// @param {variable} $max-vw [900px] - Stop scaling font larger at this screen resolution
///
/// @example
///   h1 {
///     @include fluid-type(font-size, 14px, 18px, 320px, 1366px);
///     @include fluid-type(padding-bottom padding-top, 2em, 4em, 20em, 70em);
///   }
///
/// @link https://codepen.io/danield770/pen/zdPrWz
/// @link https://codepen.io/dbox/pen/meaMba
/// @link https://madebymike.com.au/writing/precise-control-responsive-typography/
@mixin fluid-type($properties, $min-value, $max-value, $min-vw: map-get($breakpoints, s), $max-vw: map-get($breakpoints, xl)) {
    @each $property in $properties {
        #{$property}: $min-value;
    }

    //@media screen and (min-width: $min-vw) {
    @include media(">=s") {
        @each $property in $properties {
            #{$property}: calc(#{$min-value} + #{strip-units($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-units($max-vw - $min-vw)});
        }
    }

    //@media screen and (min-width: $max-vw) {
    @include media(">=xl") {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}


///
/// Line-height Crop
///
/// @see http://text-crop.eightshapes.com/
/// @see https://medium.com/codyhouse/line-height-crop-a-simple-css-formula-to-remove-top-space-from-your-text-9c3de06d7c6f
///
@mixin text-crop($line-height: 1.3, $top-adjustment: 0px, $bottom-adjustment: 0px) {
    // Configured in Step 1
    $top-crop: 12;
    $bottom-crop: 10;
    $crop-font-size: 41;
    $crop-line-height: 1.2;

    // Apply values to calculate em-based margins that work with any font size
    $dynamic-top-crop: max(($top-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;
    $dynamic-bottom-crop: max(($bottom-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;

    // Mixin output
    line-height: $line-height;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 0;
        width: 0;
    }

    &::before {
        margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
    }

    &::after {
        margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
    }
}