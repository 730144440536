/**
 * Colonna con il pulsante per eliminare una riga
 */
.woocommerce-cart {
	.woocommerce-info {
		margin-bottom: 2rem;
	}

	table.cart {
		.product-thumbnail {
			display: none; // MyMay Personalizzation
		}
	}

	.woocommerce .cart_totals {
		h2 {
			display: none;
		}

		table.shop_table {
			tr.order-total {
				background-color: #f9f9f9;
				font-size: 1.2em;

				td, th {
					border-top-color: #d6d6d6;
				}
			}
		}
	}
}

/**
 * Coupon
 */
.woocommerce #content table.cart td.actions .coupon,
.woocommerce table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
	display: flex;

	@media (max-width: 768px) {
		width: 100%;
	}

	.input-text,
	.button {
		border-radius: 3px;
		margin: 0;
		padding: 0.5rem 1rem;
	}

	.input-text {
		border-bottom-right-radius: 0;
		border-right: 0;
		border-top-right-radius: 0;
		width: 60%;
	}

	.button {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
	}
}
