////
/// ============================================================================
/// GENERIC / Media
/// ============================================================================
///
/// @group generics
////

//
// 1. Setting `vertical-align` removes the whitespace that appears under `img`
//    elements when they are dropped into a page as-is. Safer alternative to
//    using `display: block;`.
//
audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle; /* [1] */
}

//
// Add the correct display in iOS 4-7.
//
audio:not([controls]) {
	display: none;
	height: 0;
}


iframe {
	aspect-ratio: 16 / 9;
	border: 0;
	width: 100%;
}

figure {
	margin: 0;
}


//
// Fluid media for responsive purposes.
//
img, video, svg,
iframe:not([id*="ads"]) {
	height: auto;
	max-width: 100%;
}

//
// Offset `alt` text from surrounding copy.
//
img {
	font-style: italic;
}

//
// SVG elements
//
// @see https://www.24a11y.com/2018/accessible-svg-icons-with-inline-sprites/
//
svg {
	fill: currentColor;
	flex-shrink: 0;
	height: 1em;
	position: relative;
	transition: fill .3s;
	width: 1em;

	a & {
		pointer-events: none;
	}
}
