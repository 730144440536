/* --------------------------------

File#: _1_anim-menu-btn
Title: Animated Menu Button
Descr: A menu button w/ a morphing icon
Usage: codyhouse.co/license

-------------------------------- */

.anim-menu-btn {
    @extend %reset-btn;
    align-items: center;
    color: #000; // icon color
    display: flex;
    height: 48px;
    justify-content: center;
    width: 48px;

    // icons
    &__icon {
        background-image: linear-gradient(currentColor, currentColor);
        background-repeat: no-repeat;
        color: inherit;
        display: block;
        font-size: 32px;
        height: 2px;
        position: relative;
        transform: scale(1);
        width: 1em;

        &::before,
        &::after {
            background-image: inherit;
            border-radius: inherit;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        // menu to 'X' close
        &--close {
            background-size: 100% 100%;
            transition: transform 0.2s, background-size 0.2s;
            will-change: transform, background-size;

            .anim-menu-btn:active & {
                transform: scale(0.9);
            }

            &::before,
            &::after {
                transition: inherit;
                will-change: inherit;
            }

            &::before { // line top
                transform: translateY(-0.25em) rotate(0);
            }

            &::after { // line bottom
                transform: translateY(0.25em) rotate(0);
            }
        }
    }

    &--state-b {
        .anim-menu-btn__icon--close {
            background-size: 0% 100%;

            &::before {
                transform: translateY(0) rotate(45deg);
            }

            &::after {
                transform: translateY(0) rotate(-45deg);
            }
        }
    }

    // menu to arrow
    &__icon--arrow-left,
    &__icon--arrow-right,
    &__icon--arrow-up,
    &__icon--arrow-down {
        border-radius: 50em;
        transition: transform 0.2s;
        will-change: transform;

        .anim-menu-btn:active & {
            transform: scale(0.9);
        }

        &::before,
        &::after {
            transform-origin: calc(2px / 2) 50%;
            transition: transform 0.2s, width 0.2s;
            will-change: transform, width;
        }

        &::before { // line top
            transform: translateY(-0.25em) rotate(0);
        }

        &::after { // line bottom
            transform: translateY(0.25em) rotate(0);
        }
    }

    &__icon--arrow-right {
        transform: rotate(180deg);

        .anim-menu-btn:active & {
            transform: rotate(180deg) scale(0.9);
        }
    }

    &--state-b {
        .anim-menu-btn__icon--arrow-left,
        .anim-menu-btn__icon--arrow-right,
        .anim-menu-btn__icon--arrow-up,
        .anim-menu-btn__icon--arrow-down {
            &::before,
            &::after {
                width: 50%;
            }

            &::before {
                transform: translateY(0) rotate(-45deg);
            }

            &::after {
                transform: translateY(0) rotate(45deg);
            }
        }

        &:active .anim-menu-btn__icon--arrow-up {
            transform: rotate(90deg) scale(0.9);
        }

        .anim-menu-btn__icon--arrow-up {
            transform: rotate(90deg);
        }

        &:active .anim-menu-btn__icon--arrow-down {
            transform: rotate(-90deg) scale(0.9);
        }

        .anim-menu-btn__icon--arrow-down {
            transform: rotate(-90deg);
        }
    }
}