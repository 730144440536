////
/// ============================================================================
/// WOOCOMMERCE / Notify
/// ============================================================================
///
/// @group woocommerce
////

.woocommerce-error,
.woocommerce-info,
.woocommerce-message,
.woocommerce-noreviews,
.woocommerce-notice,
p.no-comments {
	@extend %c-alert;
	@include clearfix();
	clear: both;
	list-style: none;
	margin-bottom: 2.5rem;
	margin-left: 0;
	width: 100%; // Fix for display flex

	&::before {
		content: '';
	}

	a {
		color: inherit;

		&:hover {
			color: inherit;
			opacity: 0.7;
		}

		&.button:hover {
			opacity: 1;
		}
	}

	.button {
		@extend %c-btn;
		@extend %c-btn-small;
		@extend %c-btn-outline-negative;
		box-shadow: inset 0 0 0 1px currentColor;
		color: currentColor;
		float: right;

		&:hover {
			box-shadow: inset 0 0 0 1px currentColor;
			color: currentColor;
		}
	}

	pre {
		background-color: rgba(0, 0, 0, .1);
	}

	li {
		background: transparent;
	}
}

.woocommerce-error {
	$color: color(error);

	background: scale-color($color, $lightness: 50%);
	border-color: $color;
	color: darken($color, 30%);
}

.woocommerce-info,
.woocommerce-noreviews,
p.no-comments {
	$color: color(info);

	background: scale-color($color, $lightness: 50%);
	border-color: $color;
	color: darken($color, 30%);

	a {
		background-color: color(primary, base);
		border-radius: $btn-default-border-radius;
		color: #fff;
		padding: 0.2rem 0.5rem;
		text-align: center;
		text-decoration: none;
		transition: all 0.25s;
		transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
		white-space: nowrap;

		&:hover {
			background-color: color(primary, light);
			color: #fff;
			text-decoration: none;
		}
	}
}

.woocommerce-message,
.woocommerce-notice--success {
	$color: color(success);

	background: scale-color($color, $lightness: 50%);
	border-color: $color;
	color: darken($color, 30%);
}

.woocommerce-notices-wrapper:empty {
	display: none;
}

.account-rkg-warning {
	font-size: 0.85em;
}
