////
/// ============================================================================
/// OBJECT / Sidebar
/// ============================================================================
///
/// @group objects
////

.o-sidebar {
    @include media(">=l") {
        width: span(3 of 11);
    }
}