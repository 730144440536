////
/// ============================================================================
/// VARIABLES
/// ============================================================================
///
/// @group config
////

/**
 * Debug
 */
$debug: false;


/// Font Weight
$font-weight-thin: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;


/**
 * Breakpoints & Grid
 * 
 * - s .......... 480px Default for extra small devices, phone
 * - m .......... 768px Default for small devices, tablets
 * - l .......... 992px Default for medium devices, desktops
 * - xl ......... 1200px Default for large devices, wide screens
 *
 * Per ciascun breakpoint vengono settati:
 *
 * - query ...... media query
 * - susy ....... personalizzazioni della griglia
 */
$susy: (
    'columns': susy-repeat(12),
    'gutters': 2/5,
    'spread': 'narrow',
    'container-spread': 'narrow',
    'svg-grid-colors': hsl(180, 50%, 80%)
);

$container: 1650px;
$container-side: 1em;


/**
 * Breakpoint
 *
 * @link https://include-media.com/
 */
$breakpoints: (
    xs: 0,
    s: 540px,
    m: 900px,
    l: 1100px,
    xl: 1320px
);


/// Mappa colori
///
/// @prop {Color} body          [#fff]      - body
/// @prop {Color} text          [#767677]   - text
/// @prop {Color} gray-darker   [#1e1e1f]   - gray darker
/// @prop {Color} gray-dark     [#767677]   - gray dark
/// @prop {Color} gray          [#b1b0af]   - gray
/// @prop {Color} gray-light    [#c2c0c0]   - gray light
/// @prop {Color} gray-lighter  [#d4d3d2]   - gray lighter
/// @prop {Color} success       [#5cb85c]   - success
/// @prop {Color} info          [#5bc0de]   - info
/// @prop {Color} warning       [#f0ad4e]   - warning
/// @prop {Color} danger        [#d9534f]   - danger
/// @prop {Color} primary       [#222838]   - primary
/// @prop {Color} secondary     [#e31a4e]   - secondary
/// @prop {Color} tertiary      [#71bdb4]   - tertiary
///
/// @example scss - Usage
/// .foo {
///     color: color-get(body);
/// }
/// .bar {
///     color: color-get(gray, 0.5, -10%);
/// }
///
/// @example css - CSS Output
/// .foo {
///     color: #fff;
/// }
/// .bar {
///     color: hsla(0, 0%, 52%, .5);
/// }
///
/// @type Map
$colors: (
    body            : #fff,
    text            : (
        base        : #222121,
        light       : lighten(#222121, 20%),
        dark        : darken(#222121, 20%)
    ),

    gray            : (
        100         : #f9fafb, // Light Background
        200         : #f6f6f8, // Background
        300         : #c3c3c3, // Border
        400         : #9b9b9b, // Excerpt
        500         : #393939, // Subtitle
        600         : #222121, // Title
    ),

    success         : #21d492,
    info            : #3d9cd2,
    warning         : #fcd34a,
    error           : #fa3d56,

    primary         : (
        base        : #c32d32,
        light       : lighten(#c32d32, 20%),
        dark        : darken(#c32d32, 20%)
    ),
    secondary       : (
        base        : pink,
        light       : lighten(pink, 10%),
        dark        : darken(pink, 10%)
    ),
    tertiary        : (
        base        : pink,
        light       : lighten(pink, 10%),
        dark        : darken(pink, 10%)
    ),

    border: (
            base        : #e8e8e8,
            light       : #f6f6f8,
            dark        : #c3c3c3,
    ),
);


/**
 * Links
 */
$link-color                 : color(primary, base);
$link-hover-color           : color(primary, dark);
$link-visited-color         : $link-hover-color;
$link-decoration            : underline;
$link-hover-decoration      : underline;
$link-visited-decoration    : underline;


/**
 * Border radius
 */
$border-radius: 6px;


/**
 * Buttons
 */
$btn-default-font-size: 16px;
$btn-default-border-radius: $border-radius;
$btn-default-padding: 1em 2.5em;
$btn-default-text-trasform: none;


/**
 * Form
 */
$form-item-margin: 1.25rem;

$label-text-color: color(text, base);

$input-background-color: #fff;
$input-border-width: 1px;
$input-border-color: color(text, light);
$input-icon-color: color(text, base);
$input-text-color: color(text, base);
$input-placeholder-color: color(text, light);
$input-padding: 0.8em 1em;

$checkbox: rem(18px);
$checkbox-icon-color: $input-icon-color;
$radio-icon-color: $input-icon-color;

$select-icon: 30px;
$select-icon-color: color(text, base);

// WooCommerce
:root {
    --grid-columns: 4;
    --grid-gap: 2rem;

    @include media(">s") {
        --grid-columns: 8;
    }

    @include media(">m") {
        --grid-columns: 12;
    }
}
