////
/// ============================================================================
/// COMPONENT / Button
/// ============================================================================
///
/// @group components
////

%reset-btn {
	appearance: none;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	color: inherit;
	cursor: pointer;
	line-height: inherit;
	outline: 0;
	padding: 0;
	text-decoration: none;
	user-select: none;
}

%c-btn,
.c-btn {
	@extend %reset-btn;
	@extend %type-bold;
	@include fluid-type(padding-top padding-bottom, 0.8em, 1em);
	@include fluid-type(padding-left padding-right, 1em, 2.5em);
	align-items: center;
	border-radius: $btn-default-border-radius;
	display: inline-flex;
	font-size: $btn-default-font-size;
	justify-content: center;
	line-height: 1;
	margin: 0;
	max-width: 100%;
	overflow: hidden;
	position: relative;
	text-align: center;
	text-overflow: ellipsis;
	text-transform: $btn-default-text-trasform;
	transition: all 0.25s;
	transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
	vertical-align: middle;
	white-space: nowrap;

	// Color
	& {
		background-color: color(primary, base);
		color: #fff;

		&:hover {
			background-color: color(primary, dark);
			color: #fff;
			text-decoration: none;

			.c-btn__icon {
				background-color: color(primary, base);
				box-shadow: inset 0 0 0 1px color(primary, base);
				color: #fff;
			}
		}
	}

	&.disabled,
	&:disabled {
		background-color: color(gray, 400);
		box-shadow: none;
		color: #fff;
		cursor: not-allowed;

		&:hover {
			background-color: color(gray, 400);
			box-shadow: none;
			color: #fff;
		}
	}

	& > *:nth-child(2) {
		margin-left: 0.5em;
	}

	svg {
		flex-shrink: 0;
		transform: scale(1.4);
		transform-origin: center;
	}

	&__icon {
		align-items: center;
		border-radius: 10rem;
		box-shadow: inset 0 0 0 1px currentColor;
		display: inline-flex;
		height: 2.5em;
		justify-content: center;
		position: relative;
		width: 2.5em;

		svg {
			@include absolute(top 50% left 50%);
			transform: translate(-50%, -50%) scale(1.4);
			transform-origin: center;
		}
	}
}


.c-btn.-expand {
	width: 100%;
}

.c-btn.-ghost {
	background-color: transparent;
	color: color(text, base);
	padding: 0;

	&:hover {
		background-color: transparent;
		color: color(primary, base);
	}
}

.c-btn.-outline {
	background-color: transparent;
	box-shadow: inset 0 0 0 1px color(gray, 300);
	color: color(text, base);

	&:hover {
		background-color: transparent;
		box-shadow: inset 0 0 0 1px color(primary, base);
		color: color(primary, base);
	}

	&.-negative {
		box-shadow: inset 0 0 0 1px #fff;
		color: #fff;

		&:hover {
			background-color: transparent;
			box-shadow: inset 0 0 0 1px color(primary, light);
			color: color(primary, light);
		}
	}
}

.c-btn.-negative {
	background-color: transparent;
	box-shadow: inset 0 0 0 1px color(primary, base);
	color: color(primary, base);

	&:hover {
		background-color: transparent;
		box-shadow: inset 0 0 0 1px color(primary, dark);
		color: color(primary, dark);
	}
}

%c-btn-small,
.c-btn.-small {
	font-size: $btn-default-font-size - 1px;
	padding: 0.7em 1.2em;
}

%c-btn-outline-negative {
	background-color: transparent;
	box-shadow: inset 0 0 0 1px #fff;
	color: color(text, base);
	color: #fff;

	&:hover {
		background-color: transparent;
		box-shadow: inset 0 0 0 1px color(primary, light);
		color: color(primary, light);
	}
}
