////
/// ============================================================================
/// UTILITY / Background
/// ============================================================================
///
/// @group utilities
////

/**
 * Night
 */
.u-bg-night {
    &,
    & > h1,
    & > h2,
    & > h3 {
        color: #fff;
    }

    &.-primary {
        background-color: color(primary, base);
    }
    &.-secondary {
        background-color: color(secondary, base);
    }
    &.-black {
        background-color: color(gray, 600);
    }
}

/**
 * Light
 */
.u-bg-light {
    &,
    & > h1,
    & > h2,
    & > h3 {
        color: color(text, base);
    }

    &.-white {
        background-color: #fff;
    }
    &.-gray {
        background-color: color(gray, 200);
    }
    &.-light {
        background-color: color(gray, 100);
    }

    &.-lightGradientTop {
        background: linear-gradient(15deg, color(gray, 100), cubic-bezier(1, 0.55, 0.42, 1), transparent); // https://larsenwork.com/easing-gradients/#editor
    }

    &.-lightGradientBottom {
        background: linear-gradient(175deg, color(gray, 100), cubic-bezier(1, 0.55, 0.42, 1), transparent); // https://larsenwork.com/easing-gradients/#editor
    }
}