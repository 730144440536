////
/// ============================================================================
/// OBJECT / Grid
/// ============================================================================
///
/// @group objects
////

.o-grid {
    @include clearfix;

    &__span {
        float: left;
        margin-right: gutter();
        width: 100%;
        &:last-of-type {
            margin-right: 0;
        }
    }
}
