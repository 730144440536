/* --------------------------------

File#: _1_modal-window
Title: Modal Window
Descr: A modal dialog used to display critical information
Usage: codyhouse.co/license

-------------------------------- */

.modal {
	align-items: center;
	background-color: hsla(240, 8%, 12%, 0.9);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	opacity: 0;
	padding-left: 2.5em;
	padding-right: 2.5em;
	position: fixed;
	top: 0;
	visibility: hidden;
	width: 100%;
	z-index: 99999;

	&:not(.modal--is-visible) {
		background-color: transparent;
		pointer-events: none;
	}
}

.modal--is-visible {
	opacity: 1;
	visibility: visible;
}

.modal__content {
	box-shadow: rgba(0, 0, 0, 0.03) 0 0.9px 1.5px 0, rgba(0, 0, 0, 0.08) 0 3.1px 5.5px 0, rgba(0, 0, 0, 0.12) 0 14px 25px 0;
	max-height: 100%;
	max-width: 64rem;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;
}

// close button
.modal__close-btn {
	align-items: center;
	border: 0;
	border-radius: 50%;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	transition: 0.2s;

	svg {
		font-size: 1.5em;
	}
}

.modal__close-btn--outer { // close button - outside the modal__content
	background-color: hsla(240, 8%, 12%, 0.8);
	color: #fff;
	height: 48px;
	position: fixed;
	right: 1em;
	top: 1em;
	width: 48px;
	z-index: 10;

	&:hover {
		background-color: hsl(240, 8%, 12%);
	}
}

.modal__close-btn--inner { // close button - inside the modal__content
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.024) 0 0.3px 0.4px 0, rgba(0, 0, 0, 0.05) 0 0.9px 1.5px 0, rgba(0, 0, 0, 0.1) 0 3.5px 6px 0;
	height: 2em;
	width: 2em;

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.03) 0 0.9px 1.5px 0, rgba(0, 0, 0, 0.08) 0 3.1px 5.5px 0, rgba(0, 0, 0, 0.12) 0 14px 25px 0;
	}
}

// animations
:root {
	--modal-transition-duration: 0.2s;
}

@media (prefers-reduced-motion: no-preference) {
	.modal--animate-fade {
		transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
		--modal-transition-duration: 0.2s;

		&.modal--is-visible {
			transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s;
		}
	}

	.modal--animate-scale,
	.modal--animate-translate-up,
	.modal--animate-translate-down,
	.modal--animate-translate-right,
	.modal--animate-translate-left {
		transition: opacity var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
		--modal-transition-duration: 0.2s;

		.modal__content {
			transition: transform var(--modal-transition-duration) var(--ease-out);
			will-change: transform;
		}

		&.modal--is-visible {
			transition: opacity var(--modal-transition-duration), visibility 0s;

			.modal__content {
				transform: scale(1); // reset all transformations
			}
		}
	}

	.modal--animate-slide-up,
	.modal--animate-slide-down,
	.modal--animate-slide-right,
	.modal--animate-slide-left {
		transition: opacity 0s var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
		--modal-transition-duration: 0.3s;

		.modal__content {
			transition: transform var(--modal-transition-duration) var(--ease-out);
			will-change: transform;
		}

		&.modal--is-visible {
			transition: background-color var(--modal-transition-duration), visibility 0s;

			.modal__content {
				transform: scale(1); // reset all transformations
			}
		}
	}

	// scale
	.modal--animate-scale {
		.modal__content {
			transform: scale(0.95);
		}
	}

	// translate
	.modal--animate-translate-up {
		.modal__content {
			transform: translateY(40px);
		}
	}

	.modal--animate-translate-down {
		.modal__content {
			transform: translateY(-40px);
		}
	}

	.modal--animate-translate-right {
		.modal__content {
			transform: translateX(-40px);
		}
	}

	.modal--animate-translate-left {
		.modal__content {
			transform: translateX(40px);
		}
	}

	// slide
	.modal--animate-slide-up {
		.modal__content {
			transform: translateY(100%);
		}
	}

	.modal--animate-slide-down {
		.modal__content {
			transform: translateY(-100%);
		}
	}

	.modal--animate-slide-right {
		.modal__content {
			transform: translateX(-100%);
		}
	}

	.modal--animate-slide-left {
		.modal__content {
			transform: translateX(100%);
		}
	}
}

// load content - optional
.modal--is-loading {
	.modal__content {
		visibility: hidden;
	}

	.modal__loader {
		display: flex;
	}
}

.modal__loader {
	@include center(center, 48px, 48px);
	display: none;
	pointer-events: none;
}