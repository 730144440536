////
/// ============================================================================
/// ARCHIVE
/// ============================================================================
///
/// @group templates
////

.o-archive {
    &__header {
        p {
            color: color(text, light);

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &.-reduced {
            @include media(">=l") {
                width: span(8);
            }
        }

        &.-center {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__body {
        @include clearfix;
    }

    &__foter {
        @include clearfix;
    }
}


/**
 * Entries
 */
.o-entries {
    @include fluid-type(margin-top margin-bottom, -1rem, -1.5rem); // .c-entry
    @include clearfix;
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;

    .o-archive & {
        @include fluid-type(margin-top margin-bottom, -1rem, -2rem); // .c-entry
    }

    &.-center {
        justify-content: center;
    }

    @each $breakpoint in map-keys($breakpoints) {
        @include media(">=#{$breakpoint}") {
            &.-col2\@#{$breakpoint} > * {
                margin-right: gutter();
                width: span(6);

                &:nth-of-type(2n),
                &:last-of-type {
                    margin-right: 0;
                }
            }

            &.-col3\@#{$breakpoint} > * {
                margin-right: gutter();
                width: span(4);

                &:nth-of-type(2n) {
                    margin-right: gutter();
                }

                &:nth-of-type(3n),
                &:last-of-type {
                    margin-right: 0;
                }
            }

            &.-col4\@#{$breakpoint} > * {
                margin-right: gutter();
                width: span(3);

                &:nth-of-type(2n),
                &:nth-of-type(3n) {
                    margin-right: gutter();
                }

                &:nth-of-type(4n),
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}