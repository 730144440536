
.autocomplete {
    background: #fff;
    border: 1px solid #ddd;
    font-size: 15px;

    z-index: 1000;
    overflow: auto;

    * {
        font: inherit;
    }

    & > div {
        cursor: pointer;
        padding: 0.5rem;

        &:hover:not(.group),
        &.selected {
            background: #eee;
        }
    }

    .group {
        background: #eee;
    }
}
