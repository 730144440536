////
/// ============================================================================
/// COMPONENT / Card
/// ============================================================================
///
/// @group components
////

.c-card {
    background: #fff;
    border-radius: $border-radius;
    box-shadow: 0 23px 35px -8px rgba(0, 0, 0, 0.2), inset 0 0 0 1px color(gray, 300);
    margin-bottom: 2rem;
    max-width: 100%;
    padding: 2rem;
    position: relative;
}