////
/// ============================================================================
/// COMPONENT / Pagination
/// ============================================================================
///
/// @group components
////

.c-pagination {
    $size: 44px;

    @include clearfix;
    @extend %type-bold;
    color: color(text, base);
    font-size: 16px;
    text-align: center;

    &__current,
    &__url,
    &__dots {
        align-items: center;
        border-radius: $size;
        color: color(text, light);
        display: inline-flex;
        height: $size;
        justify-content: center;
        line-height: $size;
        margin-right: 0.5em;
        vertical-align: middle;
        width: $size;

        &:last-child {
            margin-right: 0;
        }
    }

    &__arrow,
    &__url {
        color: currentColor;
        cursor: pointer;

        &:hover {
            color: color(primary, base);
        }
    }

    &__arrow {
        height: $size;
        line-height: $size;

        & > *:not(:last-child) {
            margin-right: 0.5em;
        }

        svg {
            font-size: 24px;
        }

        &.-prev {
            margin-right: 5%;
        }

        &.-next {
            margin-left: 5%;
        }
    }

    &__current {
        box-shadow: inset 0 0 0 2px color(primary, base);
        color: color(primary, base);
    }
}