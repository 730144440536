////
/// ============================================================================
/// UTILITY / Ratio
/// ============================================================================
///
/// @group utilities
////

.u-ratio {
    position: relative;
    overflow: hidden;

    &::before {
        display: block;
        width: 100%;
        content: '';
    }

    $data-ratios: 1 1,
                  6 5,
                  16 9 !default;

    @each $ratio in $data-ratios {
        $ratio-1: nth($ratio, 1);
        $ratio-2: nth($ratio, 2);
        &[data-ratio="#{$ratio-1}/#{$ratio-2}"]::before {
            padding-bottom: percentage($ratio-2 / $ratio-1);
        }
    }
}