////
/// ============================================================================
/// KK STAR RATINGS
/// ============================================================================
///
/// @group vendors
////

.kk-star-ratings {
    display: flex;
    flex-direction: row-reverse;
    .kksr-legend {
        font-size: 0;
        margin-left: 0;

        span,
        strong {
            @extend .u-type.-xs;
        }
    }

    .kksr-muted {
        padding: 0 1px;
    }
}