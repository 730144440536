////
/// ============================================================================
/// COMPONENT / Breadcrimb
/// ============================================================================
///
/// @group components
////

.c-breadcrumb {
    @extend %type-bold;
    text-transform: uppercase;

    &__wrap {
        span {
            display: flex;
            overflow: hidden;
            padding-left: 0.5em;
            white-space: nowrap;
        }

        & > span {
            padding-left: 0;

            > span {
                padding-left: 0;

                a {
                    flex: 0 0 auto;
                    min-width: auto;
                }
            }
        }

        a, strong {
            flex: 0 1 auto;
            min-width: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }

        a {
            color: color(text, light);
            padding-right: 0.5em;
            text-decoration: none;

            &:hover {
                color: color(primary, base);
                flex: 0 0 auto;
                min-width: auto;
            }
        }

        strong {
            padding-left: 0.5em;
        }
    }
}