////
/// ============================================================================
/// LEARNDASH / Fix
/// ============================================================================
///
/// @group learndash
////

body.learndash-cpt.learndash-embed-responsive .ld-video {
	margin-bottom: 1rem;
}


.learndash-wrapper {
	#learndash_mark_complete_button,
	.learndash_mark_complete_button {
		position: static;

		@include media("<640px") {
			margin-left: 0;
		}
	}

	.ld-focus .ld-focus-header .ld-content-action {
		#sfwd-mark-complete,
		.sfwd-mark-complete {
			width: auto;
		}

		@include media("<768px") {
			display: none;
		}
	}
}