////
/// ============================================================================
/// LEARNDASH / Focus Mode Header
/// ============================================================================
///
/// @group learndash
////

.learndash-wrapper .ld-focus {
	@include media(">=769px") {
		.ld-focus-header {
			height: 101px;
		}

		.ld-focus-sidebar {
			padding-top: 100px;
		}

		.ld-focus-main .ld-focus-content {
			padding: 8em 5em 5em 5em;
		}
	}
}
