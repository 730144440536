////
/// ============================================================================
/// HEADER
/// ============================================================================
///
/// @group templates
////

.c-icon-cart,
.c-icon-account {
	position: relative;

	svg {
		color: currentColor;
		font-size: 24px;
		position: relative;
		transition: color 0.2s;
	}

	span {
		margin-left: 0.5em;

		@include media(">=m") {
			display: none;
		}
	}

	small {
		background-color: #e47c7c;
		border-radius: 50%;
		color: #fff;
		display: none;
		font-family: sans-serif;
		font-size: 11px;
		font-weight: 700;
		height: 16px;
		line-height: 16px;
		position: absolute;
		right: -9px;
		text-align: center;
		top: 5px;
		width: 16px;

		@include media(">=m") {
			display: inline-block;
			right: 0;
			top: 7px;
		}
	}
}
