////
/// ============================================================================
/// OBJECT / Layout
/// ============================================================================
///
/// @group objects
////

.o-layout {
    @include clearfix;

    // Layout Contenuto Centrato
    &.-reduced {
        @include media(">=l") {
            margin-left: auto;
            margin-right: auto;
            width: span(8);
            
            .c-form .o-grid__span {
                margin-right: gutter(8);
                width: span(4 of 8);
                &:nth-of-type(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    @include media(">=m") {
        .c-form .o-grid__span {
            margin-right: gutter();
            width: span(6);
            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }
    }
}
