////
/// ============================================================================
/// COMPONENT / Testimonials
/// ============================================================================
///
/// @group components
////

.c-testimonials {
    @include media(">=m") {
        margin-left: auto;
        margin-right: auto;
        width: span(6 of 8);
        .-post & {
            width: 100%;
        }
    }
    @include media(">=l") {
        width: span(6 of 8);
        .-post & {
            width: 100%;
        }
    }

    &__wrap {
    }

    &__nav {
        @include center(top 5em, auto, 12em, 10);
    }

    &__pag {
        bottom: 0;
        position: relative;
    }
}

.c-testimonial {
    &__meta {
        h5 {
            small {
                @extend %type-regular;
                display: block;
            }
        }
    }

    &__text {
        position: relative;
    }

    .-slide & {
        text-align: center;

        .c-testimonial__text {
            margin: auto;
            max-width: 660px;

            svg {
                @include transform($zindex: -1);
                fill: color(gray, 200);
                height: 100%;
                width: 100%;
            }
        }
    }
}

.swiper-button-next,
.swiper-button-prev {
    background: none;
    &::after {
        display: none;
    }
}